import {
  UnassignDriversModalState,
  DeleteJobsModalState,
  JobModalState,
  ModalState,
  ConnectCartwheelModalState,
  CartwheelEditPayoutModalState,
} from 'src/reducers/modals/types'
import { State } from '../reducers/types'

export const getConnectCartwheelModal = (state: State): ConnectCartwheelModalState =>
  state.modals.cartwheel

export const getJobModal = (state: State): JobModalState => state.modals.job

export const getDeleteJobsModal = (state: State): DeleteJobsModalState => state.modals.deleteJob

export const getUnassignDriversModal = (state: State): UnassignDriversModalState =>
  state.modals.unassignDriver

export const getUploadCSVModal = (state: State): ModalState => state.modals.uploadCSV

export const getCancelationPolicyModal = (state: State): ModalState =>
  state.modals.cancelationPolicy

export const getDeleteCartwheelAccountModal = (state: State): ModalState =>
  state.modals.deleteCartwheelAccount

export const getCartwheelEditPayoutModal = (state: State): CartwheelEditPayoutModalState =>
  state.modals.cartwheelEditPayout
