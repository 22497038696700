import { ReactNode, memo, useCallback, useMemo } from 'react'
import { Modal, Typography } from '@mui/material'
import { CloseButton } from 'src/components/shared/CloseButton'
import tw from 'twin.macro'

interface Props {
  isOpen?: boolean
  closeOnBackdrop?: boolean
  title?: string
  children?: ReactNode
  onClose(): void
  onBack?(): void
}

const DrawerModalComponent = ({
  isOpen,
  children,
  title,
  closeOnBackdrop,
  onBack,
  onClose,
}: Props) => {
  const container = useMemo(() => document.getElementById('root'), [])

  const handleClose = useCallback(
    (_e: any, reason: string) => {
      if (!closeOnBackdrop && reason && reason == 'backdropClick') {
        return
      }

      onClose()
    },
    [closeOnBackdrop, onClose],
  )

  return (
    <Modal
      container={container}
      open={Boolean(isOpen)}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div css={tw`w-[420px] bg-white absolute right-0 h-full p-8 overflow-scroll`}>
        {onBack && (
          <div css={tw`flex flex-row items-center mb-8`}>
            <CloseButton icon="back" css={tw`mr-2`} onClick={onBack} />{' '}
            <Typography variant="subtitle1">Back</Typography>
          </div>
        )}
        {title && (
          <Typography css={tw`mb-8`} variant="h4">
            {title}
          </Typography>
        )}
        {children}
        <CloseButton css={tw`absolute right-8 top-8`} onClick={onClose} />
      </div>
    </Modal>
  )
}

export const DrawerModal = memo(DrawerModalComponent)
