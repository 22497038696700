import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  SetConnectCartwheelModal,
  SetJobModal,
  SetDeleteJobsModal,
  SetUnassignDriversModal,
  SetUploadCSVModal,
  SetCancelationPolicyModal,
  SetDeleteCartwheelAccountModal,
  SetCartwheelEditPayoutModal,
} from 'src/constants/actionTypes'
import { Job } from 'src/interfaces/jobs'
import {
  getCancelationPolicyModal,
  getConnectCartwheelModal,
  getUnassignDriversModal,
  getUploadCSVModal,
  getJobModal,
  getDeleteJobsModal,
  getDeleteCartwheelAccountModal,
  getCartwheelEditPayoutModal,
} from 'src/selectors/modals'

export const useConnectCartwheelModal = () => {
  const dispatch = useDispatch()

  const { isOpen, editMode } = useSelector(getConnectCartwheelModal)

  const openConnectCartwheelModal = useCallback((editMode?: boolean) => {
    dispatch(SetConnectCartwheelModal.success({ isOpen: true, editMode }))
  }, [])

  const closeConnectCartwheelModal = useCallback(() => {
    dispatch(SetConnectCartwheelModal.success({ isOpen: false }))
  }, [])

  return {
    isConnectCartwheelModalOpen: isOpen,
    connectCartwheelModalEditMode: editMode,
    openConnectCartwheelModal,
    closeConnectCartwheelModal,
  }
}

export const useJobModal = () => {
  const dispatch = useDispatch()

  const { isOpen, job } = useSelector(getJobModal)

  const openJobModal = useCallback((job?: Job) => {
    dispatch(SetJobModal.success({ isOpen: true, job }))
  }, [])

  const closeJobModal = useCallback(() => {
    dispatch(SetJobModal.success({ isOpen: false }))
  }, [])

  return {
    job,
    isJobModalOpen: isOpen,
    openJobModal,
    closeJobModal,
  }
}

export const useDeleteJobsModal = () => {
  const dispatch = useDispatch()

  const { isOpen, jobIds } = useSelector(getDeleteJobsModal)

  const openDeleteJobsModal = useCallback((jobIds?: Array<string>) => {
    dispatch(SetDeleteJobsModal.success({ isOpen: true, jobIds }))
  }, [])

  const closeDeleteJobsModal = useCallback(() => {
    dispatch(SetDeleteJobsModal.success({ isOpen: false }))
  }, [])

  return {
    jobIds,
    isDeleteJobsModalOpen: isOpen,
    openDeleteJobsModal,
    closeDeleteJobsModal,
  }
}

export const useUnassignDriversModal = () => {
  const dispatch = useDispatch()

  const { isOpen, jobIds } = useSelector(getUnassignDriversModal)

  const openUnassignDriversModal = useCallback((jobIds?: Array<string>) => {
    dispatch(SetUnassignDriversModal.success({ isOpen: true, jobIds }))
  }, [])

  const closeUnassignDriversModal = useCallback(() => {
    dispatch(SetUnassignDriversModal.success({ isOpen: false }))
  }, [])

  return {
    jobIds,
    isUnassignDriversModalOpen: isOpen,
    openUnassignDriversModal,
    closeUnassignDriversModal,
  }
}

export const useUploadCSVModal = () => {
  const dispatch = useDispatch()

  const { isOpen } = useSelector(getUploadCSVModal)

  const openUploadCSVModal = useCallback(() => {
    dispatch(SetUploadCSVModal.success({ isOpen: true }))
  }, [])

  const closeUploadCSVModal = useCallback(() => {
    dispatch(SetUploadCSVModal.success({ isOpen: false }))
  }, [])

  return {
    isUploadCSVModalOpen: isOpen,
    openUploadCSVModal,
    closeUploadCSVModal,
  }
}

interface CancelationPolicyModalParams {
  showBackButton?: boolean
}

export const useCancelationPolicyModal = (params?: CancelationPolicyModalParams) => {
  const dispatch = useDispatch()

  const { isOpen, showBackButton } = useSelector(getCancelationPolicyModal)

  const openCancelationPolicyModal = useCallback(() => {
    dispatch(
      SetCancelationPolicyModal.success({ isOpen: true, showBackButton: params?.showBackButton }),
    )
  }, [])

  const closeCancelationPolicyModal = useCallback(() => {
    dispatch(SetCancelationPolicyModal.success({ isOpen: false }))
  }, [])

  return {
    isCancelationPolicyModalOpen: isOpen,
    showCancelationPolicyModalBackButton: showBackButton,
    openCancelationPolicyModal,
    closeCancelationPolicyModal,
  }
}

export const useDeleteCartwheelAccountModal = () => {
  const dispatch = useDispatch()

  const { isOpen } = useSelector(getDeleteCartwheelAccountModal)

  const openDeleteCartwheelAccountModal = useCallback(() => {
    dispatch(SetDeleteCartwheelAccountModal.success({ isOpen: true }))
  }, [])

  const closeDeleteCartwheelAccountModal = useCallback(() => {
    dispatch(SetDeleteCartwheelAccountModal.success({ isOpen: false }))
  }, [])

  return {
    isDeleteCartwheelAccountModalOpen: isOpen,
    openDeleteCartwheelAccountModal,
    closeDeleteCartwheelAccountModal,
  }
}

export const useCartwheelEditPayoutModal = () => {
  const dispatch = useDispatch()

  const { isOpen, jobIds } = useSelector(getCartwheelEditPayoutModal)

  const openCartwheelEditPayoutModal = useCallback((jobIds: Array<string>) => {
    dispatch(SetCartwheelEditPayoutModal.success({ isOpen: true, jobIds }))
  }, [])

  const closeCartwheelEditPayoutModal = useCallback(() => {
    dispatch(SetCartwheelEditPayoutModal.success({ isOpen: false }))
  }, [])

  return {
    isCartwheelEditPayoutModalOpen: isOpen,
    jobIds,
    openCartwheelEditPayoutModal,
    closeCartwheelEditPayoutModal,
  }
}
