import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { CartwheelDashboard } from 'src/components/jobs/cartwheel/grid/CartwheelDashboard'
import { ROUTES } from 'src/constants/routes'
import { getIsUserInitialized, getUser } from 'src/selectors/auth'

const ImportCartwheelPageComponent = () => {
  const user = useSelector(getUser)
  const isUserInitialized = useSelector(getIsUserInitialized)

  if (!isUserInitialized) {
    return <div />
  }

  if (!user?.partner.cartwheel) {
    return <Navigate to={ROUTES.JOBS_DASHBOARD} replace />
  }

  return <CartwheelDashboard />
}

export const ImportCartwheelPage = memo(ImportCartwheelPageComponent)
