import { Logout } from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { UIState } from './types'

const getInitialState = (): UIState => ({})

export const uiReducer = (state: UIState = getInitialState(), action: Action<any>): UIState => {
  switch (action.type) {
    case Logout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
