import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { createAsyncAction, Action } from 'src/utils/reduxUtils'

export const useAsyncDispatchWithErrorToast = () => {
  const dispatch = useDispatch()
  const snackbar = useSnackbar()

  const asyncDispatchWithErrorToast = useCallback(
    async <T, K>(action: Action<K>): Promise<T | null> => {
      try {
        const result = await createAsyncAction<T, K>(dispatch, action)

        return result
      } catch (err: any) {
        const errorMessage =
          typeof err?.payload?.message === 'string' ? err?.payload?.message : 'Something went wrong'

        snackbar?.enqueueSnackbar(errorMessage, { variant: 'error' })

        return null
      }
    },
    [],
  )

  return { asyncDispatchWithErrorToast }
}
