import React from 'react'
// import { CancelationPolicyForm } from './cancelation-policy-form/CancelationPolicyForm'
import { useCancelationPolicyModal } from 'src/hooks/modals'
// import { useSelector } from 'react-redux'
// import { getCancelationPolicies } from 'src/selectors/auth'
import { DrawerModal } from 'src/components/shared/modal/DrawerModal'
import { CancelationPolicies } from './CancelationPolicies'

export const CancelationPolicyModal = () => {
  const {
    isCancelationPolicyModalOpen,
    showCancelationPolicyModalBackButton,
    closeCancelationPolicyModal,
  } = useCancelationPolicyModal()

  // const cancelationPolicies = useSelector(getCancelationPolicies)

  return (
    <DrawerModal
      closeOnBackdrop
      isOpen={isCancelationPolicyModalOpen}
      title="Cancellation policy"
      onClose={closeCancelationPolicyModal}
      onBack={showCancelationPolicyModalBackButton ? closeCancelationPolicyModal : undefined}
    >
      {/* <CancelationPolicyForm
        initialValues={cancelationPolicies}
        onSubmit={closeCancelationPolicyModal}
      /> */}

      <CancelationPolicies />
    </DrawerModal>
  )
}
