// import { Button, SvgIcon } from '@mui/material'
import { InfoContainer } from 'src/components/shared/InfoContainer'
// import { useCancelationPolicyModal } from 'src/hooks/modals'
// import { ChevronRight } from '@mui/icons-material'
import { memo } from 'react'
import tw from 'twin.macro'
import { CancelationPolicies } from './CancelationPolicies'

const CancelationPolicyInfoContainerComponent = () => {
  // const { openCancelationPolicyModal } = useCancelationPolicyModal({ showBackButton: true })

  return (
    <InfoContainer css={tw`mb-8`}>
      <CancelationPolicies />
      {/* <Button
        endIcon={
          <SvgIcon>
            <ChevronRight />
          </SvgIcon>
        }
        variant="text"
        css={tw`underline text-start p-0 justify-start mt-8`}
        onClick={openCancelationPolicyModal}
      >
        Edit cancelation policies
      </Button> */}
    </InfoContainer>
  )
}

export const CancelationPolicyInfoContainer = memo(CancelationPolicyInfoContainerComponent)
