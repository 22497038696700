import { css, SvgIcon, Typography } from '@mui/material'
import { FC, SVGProps, useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { colors } from 'src/theme'
import tw from 'twin.macro'

interface Props {
  route?: string
  label: string
  Icon: FC<SVGProps<any>>
  isHovered: boolean
  onClick?(): void
}

export const SecureLeftMenuItem = ({ route, label, Icon, isHovered, onClick }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()

  const locationMatch = useMemo(() => {
    if (!route) {
      return false
    }

    return location.pathname.includes(route)
  }, [route, location])

  const handleNavigate = useCallback(() => {
    if (onClick) {
      onClick()
      return
    }

    if (route) {
      navigate(route)
      return
    }
  }, [route, onClick])

  return (
    <div
      css={[
        styles.root,
        tw`px-6 h-[52px] flex items-center cursor-pointer transition duration-300`,
      ]}
      onClick={handleNavigate}
    >
      <SvgIcon css={tw`w-5 h-5 mr-3`}>
        <Icon color={locationMatch ? colors.BRAND_BLUE : colors.GRAY_DARK_COOL} />
      </SvgIcon>
      <Typography
        css={[
          tw`whitespace-nowrap opacity-0 transition duration-300`,
          isHovered ? tw`opacity-100` : null,
        ]}
        color={locationMatch ? colors.BRAND_BLUE : colors.GRAY_DARK_COOL}
        fontWeight={500}
      >
        {label}
      </Typography>
    </div>
  )
}

const styles = {
  root: css({
    '&:hover': {
      background: colors.GRAY_LIGHT_COOL,
    },
  }),
}
