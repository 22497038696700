import { ReactNode } from 'react'
import { SecureLeftMenu } from './left-menu/SecureLeftMenu'
import tw from 'twin.macro'

interface Props {
  children: ReactNode
}

export const SecurePage = ({ children }: Props) => {
  return (
    <div css={tw`h-full`}>
      <div css={tw`flex h-full pl-20`}>
        <SecureLeftMenu />
        {children}
      </div>
    </div>
  )
}
