import React from '@mui/material'
import { useDeleteCartwheelAccountModal } from 'src/hooks/modals'
import { memo, useCallback, useState } from 'react'
import { CommonModal } from 'src/components/shared/modal/CommonModal'
import { useDispatch } from 'react-redux'
import { DeleteCartwheelAccount, FetchUser } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { showToast } from 'src/utils/toast'

const DeleteCartwheelAccountModalComponent = () => {
  const dispatch = useDispatch()

  const { isDeleteCartwheelAccountModalOpen, closeDeleteCartwheelAccountModal } =
    useDeleteCartwheelAccountModal()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleDelete = useCallback(async () => {
    setIsLoading(true)

    try {
      await createAsyncAction(dispatch, DeleteCartwheelAccount.request())
      await createAsyncAction(dispatch, FetchUser.request())

      showToast('Cartwheel account has been deleted', {
        variant: 'success',
      })
    } catch (error: unknown) {
      showToast(error as string, {
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }

    closeDeleteCartwheelAccountModal()
  }, [])

  return (
    <CommonModal
      isOpen={isDeleteCartwheelAccountModalOpen}
      title="Delete Cartwheel account?"
      text="Are you sure you want to delete your Cartwheel account?"
      closeLabel="Cancel"
      submitLabel="Delete"
      isLoading={isLoading}
      onSubmit={handleDelete}
      onClose={closeDeleteCartwheelAccountModal}
    />
  )
}

export const DeleteCartwheelAccountModal = memo(DeleteCartwheelAccountModalComponent)
