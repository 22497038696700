import React, { memo, useCallback, useMemo } from 'react'
import { SvgIcon } from '@mui/material'
import {
  ChevronDownMinor,
  AddNoteMajor,
  UploadMajor,
  IncomingMajor,
  CirclePlusMinor,
} from '@shopify/polaris-icons'
import { useConnectCartwheelModal, useJobModal, useUploadCSVModal } from 'src/hooks/modals'
import { MenuActionItem } from 'src/interfaces/ui'
import { ActionMenu } from 'src/components/shared/ActionMenu'
import { useSelector } from 'react-redux'
import { getUser } from 'src/selectors/auth'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import { LoadingButton } from '@mui/lab'

interface Props {
  className?: string
}

const JobsDashboardAddJobButtonComponent = ({ className }: Props) => {
  const navigate = useNavigate()

  const { openJobModal } = useJobModal()
  const { openUploadCSVModal } = useUploadCSVModal()
  const { openConnectCartwheelModal } = useConnectCartwheelModal()

  const user = useSelector(getUser)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleAddManually = useCallback(() => {
    openJobModal()
    handleCloseMenu()
  }, [])

  const handleUploadCsvFile = useCallback(() => {
    openUploadCSVModal()
    handleCloseMenu()
  }, [])

  const handleImportFromCartwheel = useCallback(() => {
    if (!user?.partner.cartwheel) {
      openConnectCartwheelModal()

      return
    }

    navigate(ROUTES.IMPORT_CARTWHEEL_URL)
  }, [])

  const actionItems: MenuActionItem[] = useMemo(
    () => [
      {
        label: 'Add manually',
        Icon: AddNoteMajor,
        onClick: handleAddManually,
      },
      {
        label: 'Upload CSV File',
        Icon: UploadMajor,
        onClick: handleUploadCsvFile,
      },
      {
        label: 'Import from Cartwheel',
        Icon: IncomingMajor,
        onClick: handleImportFromCartwheel,
      },
    ],
    [handleUploadCsvFile, handleAddManually, handleImportFromCartwheel],
  )

  return (
    <>
      <LoadingButton
        id="basic-button"
        className={className}
        variant="contained"
        aria-controls={isMenuOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={handleClick}
        startIcon={
          <SvgIcon>
            <CirclePlusMinor color="white" />
          </SvgIcon>
        }
        endIcon={
          <SvgIcon>
            <ChevronDownMinor />
          </SvgIcon>
        }
      >
        Add Job
      </LoadingButton>
      <ActionMenu
        anchorEl={anchorEl}
        items={actionItems}
        isOpen={isMenuOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleCloseMenu}
      />
    </>
  )
}

export const JobsDashboardAddJobButton = memo(JobsDashboardAddJobButtonComponent)
