import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import {
  ConnectCartwheel,
  DeleteCartwheelAccount,
  FetchCartwheelJobsDashboard,
  PublishCartwheelJobs,
} from 'src/constants/actionTypes'
import { Endpoints } from 'src/constants/endpoints'
import { ApiService, callSecureApi } from '../api/callApi'

function* onFetchCartwheelJobsDashboard(action: AsyncAction<void>) {
  try {
    const { payload }: { payload: any } = yield call(
      callSecureApi,
      Endpoints.FetchCartwheelJobsDashboard,
      {
        apiService: ApiService.Paraworks,
      },
    )

    yield put(FetchCartwheelJobsDashboard.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch cartwheel jobs'

    yield put(FetchCartwheelJobsDashboard.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface ConnectCartwheelRequest {
  url: string
  email: string
  password: string
}

function* onConnectCartwheel(action: AsyncAction<ConnectCartwheelRequest>) {
  try {
    const { payload } = yield call(callSecureApi, Endpoints.ConnectCartwheel, {
      apiService: ApiService.Paraworks,
      method: 'POST',
      body: action.payload,
    })

    yield put(ConnectCartwheel.success(payload))

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to connect cartwheel account'

    yield put(ConnectCartwheel.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

function* onDeleteCartwheelAccount(action: AsyncAction<void>) {
  try {
    yield call(callSecureApi, Endpoints.DeleteCartwheelAccount, {
      apiService: ApiService.Paraworks,
      method: 'DELETE',
    })

    yield put(DeleteCartwheelAccount.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to delete cartwheel account'

    yield put(DeleteCartwheelAccount.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface CartwheelJobToPublish {
  orderId: string
  orderStatus: string
  pickupBy: string
  deliverBy: string
  pickupName: string
  pickupAddress: string
  dropoffAddress: string
  driverPay: number
}

interface PublishCartwheelJobsRequest {
  cartWheelDeliveryJobs: CartwheelJobToPublish[]
}

function* onPublishCartwheelJobs(action: AsyncAction<PublishCartwheelJobsRequest>) {
  try {
    yield call(callSecureApi, Endpoints.PublishCartwheelJobs, {
      apiService: ApiService.Paraworks,
      method: 'POST',
      body: action.payload,
    })

    yield put(PublishCartwheelJobs.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to publish cartwheel jobs'

    yield put(PublishCartwheelJobs.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* cartwheelSaga() {
  yield takeEvery(FetchCartwheelJobsDashboard.type.REQUEST, onFetchCartwheelJobsDashboard)
  yield takeEvery(ConnectCartwheel.type.REQUEST, onConnectCartwheel)
  yield takeEvery(DeleteCartwheelAccount.type.REQUEST, onDeleteCartwheelAccount)
  yield takeEvery(PublishCartwheelJobs.type.REQUEST, onPublishCartwheelJobs)
}
