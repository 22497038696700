import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { createContext } from 'react'
import { CartwheelJob } from 'src/interfaces/jobs'

export interface ICartwheelDashboardContext {
  searchQuery: string
  setSearchQuery(searchQuery: string): void

  refreshJobs(): void
  isRefreshJobsLoading: boolean

  selectedRows: GridRowSelectionModel
  setSelectedRows(rowSelectionModel: GridRowSelectionModel): void

  selectedCartwheelJobs: Array<CartwheelJob>

  partialEditCartwheelJobs(valueList: Array<Partial<CartwheelJob>>): void
}

export const CartwheelDashboardContext = createContext<ICartwheelDashboardContext>({
  searchQuery: '',
  setSearchQuery: () => {},

  refreshJobs: () => {},
  isRefreshJobsLoading: false,

  selectedRows: [],
  setSelectedRows: () => {},

  selectedCartwheelJobs: [],

  partialEditCartwheelJobs: () => {},
})
