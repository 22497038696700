const BASE_URL = '/'

const LOGIN_URL = `${BASE_URL}login`

const JOBS_URL = `${BASE_URL}jobs`
const JOBS_DASHBOARD = `${JOBS_URL}/dashboard`
const IMPORT_CARTWHEEL_URL = `${JOBS_URL}/import-from-cartwheel`

const ACCOUNT_URL = `${BASE_URL}account`

export const ROUTES = {
  BASE_URL,

  LOGIN_URL,

  JOBS_URL,
  JOBS_DASHBOARD,
  IMPORT_CARTWHEEL_URL,

  ACCOUNT_URL,
}
