import { GridPaginationModel, GridPinnedColumns, GridRowSelectionModel } from '@mui/x-data-grid-pro'
import React, { memo, useMemo } from 'react'
import { GridTable } from 'src/components/shared/grid/GridTable'
import { cartwheelJobsDashboardColumns } from 'src/constants/jobs/cartwheelJobsDashboardGrid'
import { CartwheelJob } from 'src/interfaces/jobs'
import tw from 'twin.macro'

interface Props {
  jobs: Array<CartwheelJob>
  isLoading: boolean
  paginationModel: GridPaginationModel
  pageSizeOptions: Array<number>
  rowSelectionModel: GridRowSelectionModel
  onPaginationModelChange(paginationModel: GridPaginationModel): void
  onRowSelectionModelChange(rowSelectionModel: GridRowSelectionModel): void
}

const CartwheelGridComponent = ({
  jobs,
  isLoading,
  paginationModel,
  pageSizeOptions,
  rowSelectionModel,
  onPaginationModelChange,
  onRowSelectionModelChange,
}: Props) => {
  const gridPinnedColumns = useMemo<GridPinnedColumns>(() => {
    return {
      right: ['driverPay'],
    }
  }, [])

  return (
    <div css={tw`w-full h-[600px] mt-4`}>
      <GridTable
        rows={jobs}
        columns={cartwheelJobsDashboardColumns}
        pinnedColumns={gridPinnedColumns}
        checkboxSelection
        rowSelectionModel={rowSelectionModel}
        loading={isLoading}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        pageSizeOptions={pageSizeOptions}
        getRowId={(row) => row.orderId}
        onRowSelectionModelChange={onRowSelectionModelChange}
      />
    </div>
  )
}

export const CartwheelGrid = memo(CartwheelGridComponent)
