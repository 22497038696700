import Button from '@mui/material/Button'
import React, { memo, useCallback, useMemo } from 'react'
import tw from 'twin.macro'
import { SvgIcon, Typography } from '@mui/material'
import { EditMinor, DeleteMinor, CaretDownMinor, CaretUpMinor } from '@shopify/polaris-icons'
import { colors } from 'src/theme'
import { ActionMenu } from 'src/components/shared/ActionMenu'
import { MenuActionItem } from 'src/interfaces/ui'
import { useConnectCartwheelModal, useDeleteCartwheelAccountModal } from 'src/hooks/modals'
import { useSelector } from 'react-redux'
import { getUser } from 'src/selectors/auth'

const CartwheelAccountButtonComponent = () => {
  const { openConnectCartwheelModal } = useConnectCartwheelModal()
  const { openDeleteCartwheelAccountModal } = useDeleteCartwheelAccountModal()

  const user = useSelector(getUser)

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleOpenCartwheelAccountModal = useCallback(() => {
    openConnectCartwheelModal(true)
    handleCloseMenu()
  }, [])

  const handleOpenDeleteAccountModal = useCallback(() => {
    openDeleteCartwheelAccountModal()
    handleCloseMenu()
  }, [])

  const actionItems: MenuActionItem[] = useMemo(
    () => [
      {
        label: 'Edit account details',
        Icon: EditMinor,
        onClick: handleOpenCartwheelAccountModal,
      },
      {
        label: 'Delete account',
        Icon: DeleteMinor,
        color: colors.ERROR,
        onClick: handleOpenDeleteAccountModal,
      },
    ],
    [],
  )

  if (!user?.partner.cartwheel) {
    return null
  }

  return (
    <div>
      <Typography css={tw`mb-1`} color={colors.GRAY_COOL_GRAY} fontWeight={600} variant="body2">
        Cartwheel account
      </Typography>
      <Button
        id="basic-button"
        variant="outlined"
        color="secondary"
        aria-controls={isMenuOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={handleClick}
        endIcon={
          <SvgIcon>
            {isMenuOpen ? (
              <CaretUpMinor color={colors.GRAY_DARK_COOL} />
            ) : (
              <CaretDownMinor color={colors.GRAY_DARK_COOL} />
            )}
          </SvgIcon>
        }
      >
        <Typography color={colors.GRAY_DARK_COOL} fontWeight={500} variant="body2">
          {user.partner.cartwheel.url}
        </Typography>
      </Button>
      <ActionMenu
        anchorEl={anchorEl}
        items={actionItems}
        isOpen={isMenuOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleCloseMenu}
      />
    </div>
  )
}

export const CartwheelAccountButton = memo(CartwheelAccountButtonComponent)
