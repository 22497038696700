import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import { useSelector } from 'react-redux'
import { getCancelationPolicies } from 'src/selectors/auth'
import { memo } from 'react'
import tw from 'twin.macro'

const CancelationPoliciesComponent = () => {
  const { cancelationPolicy, driverCancelationPolicy } = useSelector(getCancelationPolicies)

  return (
    <div>
      <Typography css={tw`mb-2`} fontWeight={600}>
        Cancellation Policies
      </Typography>
      <Typography css={tw`mb-6`} color={colors.GRAY_DARK_COOL}>
        {cancelationPolicy || 'No cancelation policies '}
      </Typography>
      <Typography css={tw`mb-2`} fontWeight={600}>
        Cancellation Policies
      </Typography>
      <Typography color={colors.GRAY_DARK_COOL}>
        {driverCancelationPolicy || 'No driver cancelation policies '}
      </Typography>
    </div>
  )
}

export const CancelationPolicies = memo(CancelationPoliciesComponent)
