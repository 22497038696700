import React from 'react'
import { DefaultIconProps } from './types'

export const AppLogoIcon = ({ className, color }: DefaultIconProps) => {
  return (
    <svg
      className={className}
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8029 22.8789H20.3644V26.5825H11.0952C11.0952 25.6002 11.4858 24.6582 12.1812 23.9637C12.8765 23.2691 13.8195 22.8789 14.8029 22.8789Z"
        fill={color}
      />
      <path
        d="M25.8828 4.35984C23.0416 1.96803 19.4455 0.65625 15.73 0.65625C12.0144 0.65625 8.41833 1.96803 5.57715 4.35984H25.8828Z"
        fill={color}
      />
      <path
        d="M22.2183 9.91453H30.0937C29.4869 8.57657 28.6957 7.32997 27.7431 6.21094H25.9259C24.9426 6.21094 23.9995 6.60114 23.3042 7.29569C22.6089 7.99025 22.2183 8.93227 22.2183 9.91453V9.91453Z"
        fill={color}
      />
      <path
        d="M20.3646 6.21094H3.71677C2.76424 7.32997 1.97305 8.57657 1.36621 9.91453H16.657C17.6403 9.91453 18.5834 9.52433 19.2787 8.82977C19.974 8.13521 20.3646 7.19319 20.3646 6.21094V6.21094Z"
        fill={color}
      />
      <path
        d="M9.24158 11.7656H0.665477C0.295722 12.969 0.0722902 14.2125 0 15.4692H9.24158V11.7656Z"
        fill={color}
      />
      <path
        d="M11.0571 11.793C11.0571 12.7752 11.4478 13.7172 12.1431 14.4118C12.8384 15.1064 13.7815 15.4966 14.7648 15.4966H31.4236C31.3513 14.2398 31.1279 12.9964 30.7581 11.793H11.0571Z"
        fill={color}
      />
      <path
        d="M20.3646 21.0278C20.3646 20.0456 19.9739 19.1035 19.2786 18.409C18.5833 17.7144 17.6402 17.3242 16.6569 17.3242H0.000488281C0.0727785 18.5809 0.296211 19.8244 0.665965 21.0278H20.3646Z"
        fill={color}
      />
      <path
        d="M22.2183 21.0278H30.7963C31.166 19.8244 31.3894 18.5809 31.4617 17.3242H22.2183V21.0278Z"
        fill={color}
      />
      <path
        d="M22.2183 22.8789C22.2183 23.8612 22.6089 24.8032 23.3042 25.4977C23.9995 26.1923 24.9426 26.5825 25.9259 26.5825H27.7431C28.6957 25.4635 29.4869 24.2169 30.0937 22.8789H22.2183Z"
        fill={color}
      />
      <path
        d="M5.534 26.5825C6.51733 26.5825 7.46039 26.1923 8.15571 25.4977C8.85103 24.8032 9.24166 23.8612 9.24166 22.8789H1.36621C1.97305 24.2169 2.76424 25.4635 3.71677 26.5825H5.534Z"
        fill={color}
      />
      <path
        d="M25.8828 28.4336H5.57715C8.41833 30.8254 12.0144 32.1372 15.73 32.1372C19.4455 32.1372 23.0416 30.8254 25.8828 28.4336V28.4336Z"
        fill={color}
      />
    </svg>
  )
}
