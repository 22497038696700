import Button from '@mui/material/Button'
import React, { memo, useCallback } from 'react'
import tw from 'twin.macro'
import { ListItem, ListItemText, SvgIcon, Typography } from '@mui/material'
import { CircleMinusMinor, CirclePlusMinor, LockMinor } from '@shopify/polaris-icons'
import { colors } from 'src/theme'

interface Props {
  value: string
  label?: string
  isPinned: boolean
  isExcluded: boolean
  onExclude(value: string): void
  onInclude(value: string): void
  onPin(value: string): void
  onUnpin(value: string): void
}

const GridColumnsSelectItemComponent = ({
  value,
  label,
  isPinned,
  isExcluded,
  onExclude,
  onInclude,
  onPin,
  onUnpin,
}: Props) => {
  const handleClickAddRemove = useCallback(() => {
    if (isExcluded) {
      onInclude(value)
      return
    }

    onExclude(value)
  }, [value, isExcluded, onExclude, onInclude])

  const handleClickPin = useCallback(() => {
    if (isPinned) {
      onUnpin(value)
      return
    }

    onPin(value)
  }, [value, isPinned, onPin, onUnpin])

  return (
    <ListItem css={tw`w-[240px] h-10 px-2`} disablePadding>
      <Button
        css={tw`min-w-fit`}
        color="secondary"
        disabled={isPinned}
        onClick={handleClickAddRemove}
      >
        <SvgIcon>
          {isExcluded ? (
            <CirclePlusMinor color={colors.SUCCESS} />
          ) : (
            <CircleMinusMinor color={isPinned ? colors.GRAY_MID_COOL : colors.ERROR} />
          )}
        </SvgIcon>
      </Button>
      <Button
        disabled={isExcluded}
        css={tw`min-w-fit mr-2`}
        color="secondary"
        onClick={handleClickPin}
      >
        <SvgIcon>
          <LockMinor color={isPinned ? colors.BRAND_BLUE : colors.GRAY_MID_COOL} />
        </SvgIcon>
      </Button>
      <ListItemText
        id={`list-label-${value}`}
        primary={<Typography color={colors.GRAY_COOL_GRAY}>{label}</Typography>}
      />
    </ListItem>
  )
}

export const GridColumnsSelectItem = memo(GridColumnsSelectItemComponent)
