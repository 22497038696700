import { GridColDef } from '@mui/x-data-grid-pro'
import { useCallback, useEffect, useMemo, useState } from 'react'

interface ColumnsPersistentState {
  excluded: Array<string>
  pinned: Array<string>
}

export const useGridColumns = (columnsConfig: Array<GridColDef>, persistentKey: string) => {
  const [excludedColumns, setExcludedColumns] = useState<Array<string>>([])
  const [pinnedColumns, setPinnedColumns] = useState<Array<string>>([])

  useEffect(() => {
    const persistentStateRaw = localStorage.getItem(persistentKey)
    const persistentState: ColumnsPersistentState | null = persistentStateRaw
      ? JSON.parse(persistentStateRaw)
      : null

    setExcludedColumns(persistentState?.excluded || [])
    setPinnedColumns(persistentState?.pinned || [])
  }, [])

  const handleSetExcludedColumns = useCallback((value: Array<string>) => {
    setExcludedColumns(value)

    const persistentStateRaw = localStorage.getItem(persistentKey)
    const persistentState: ColumnsPersistentState | null = persistentStateRaw
      ? JSON.parse(persistentStateRaw)
      : null

    localStorage.setItem(
      persistentKey,
      JSON.stringify({
        excluded: value,
        pinned: persistentState?.pinned || [],
      }),
    )
  }, [])

  const handleSetPinnedColumns = useCallback((value: Array<string>) => {
    setPinnedColumns(value)

    const persistentStateRaw = localStorage.getItem(persistentKey)
    const persistentState: ColumnsPersistentState | null = persistentStateRaw
      ? JSON.parse(persistentStateRaw)
      : null

    localStorage.setItem(
      persistentKey,
      JSON.stringify({
        excluded: persistentState?.excluded || [],
        pinned: value,
      }),
    )
  }, [])

  const handleAddExcludedColumn = useCallback(
    (column: string) => {
      if (excludedColumns.includes(column)) {
        return
      }

      handleSetExcludedColumns([...excludedColumns, column])
    },
    [excludedColumns],
  )

  const handleRemoveExcludedColumn = useCallback(
    (column: string) => {
      const idx = excludedColumns.findIndex((excludedColumn) => excludedColumn === column)

      if (idx === -1) {
        return
      }

      handleSetExcludedColumns([
        ...excludedColumns.slice(0, idx),
        ...excludedColumns.slice(idx + 1),
      ])
    },
    [excludedColumns],
  )

  const handleAddPinnedColumn = useCallback(
    (column: string) => {
      if (excludedColumns.includes(column)) {
        return
      }

      if (pinnedColumns.includes(column)) {
        return
      }

      handleSetPinnedColumns([...pinnedColumns, column])
    },
    [excludedColumns, pinnedColumns],
  )

  const handleRemovePinnedColumn = useCallback(
    (column: string) => {
      const idx = pinnedColumns.findIndex((pinnedColumn) => pinnedColumn === column)

      if (idx === -1) {
        return
      }

      handleSetPinnedColumns([...pinnedColumns.slice(0, idx), ...pinnedColumns.slice(idx + 1)])
    },
    [pinnedColumns],
  )

  const columns = useMemo(() => {
    return columnsConfig.map((columnConfig) => columnConfig.field)
  }, [columnsConfig])

  const includedColumns = useMemo(() => {
    return columns.filter((column) => !excludedColumns.includes(column))
  }, [excludedColumns, columns])

  return {
    columns,
    includedColumns,
    excludedColumns,
    handleAddExcludedColumn,
    handleRemoveExcludedColumn,

    pinnedColumns,
    handleAddPinnedColumn,
    handleRemovePinnedColumn,
  }
}
