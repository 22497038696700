import { FormHelperText, Typography } from '@mui/material'
import { FormControl } from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { Dayjs } from 'dayjs'
import { colors } from 'src/theme'
import tw from 'twin.macro'

interface Props {
  name?: string
  label?: string
  value?: Dayjs
  error?: boolean
  helperText?: string | false
  onChange(date: Dayjs | null): void
}

export interface BasicSelectOption {
  label: string
  value: string | number
}

export const BasicTimePicker = ({ label, value, error, helperText, onChange }: Props) => {
  return (
    <FormControl fullWidth error={error}>
      <div css={tw`space-y-1`}>
        <Typography variant="body2" fontWeight={600} color={colors.GRAY_COOL_GRAY}>
          {label}
        </Typography>
        <TimePicker value={value} onChange={onChange} />
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </div>
    </FormControl>
  )
}
