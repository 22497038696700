import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import {
  CreateJob,
  EditJob,
  DeleteJobs,
  UnassignDrivers,
  FetchJobsDashboard,
} from 'src/constants/actionTypes'
import { Endpoints } from 'src/constants/endpoints'
import { ApiService, callSecureApi } from '../api/callApi'
import { JobStatus } from 'src/constants/jobs/enums'

interface FetchJobsDashboardPayload {
  filterDateAfter?: string
  statusFilter?: Array<JobStatus>
  limit: number
  offset: number
  searchQuery: string
}

function* onFetchJobsDashboard(action: AsyncAction<FetchJobsDashboardPayload>) {
  const { filterDateAfter, limit, offset, statusFilter, searchQuery } = action.payload

  try {
    const { payload }: { payload: any } = yield call(callSecureApi, Endpoints.FetchJobsDashboard, {
      apiService: ApiService.Paraworks,
      query: {
        filterDateAfter,
        jobStatus: statusFilter,
        externalJobId: searchQuery || undefined,
        limit,
        offset,
      },
    })

    yield put(FetchJobsDashboard.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch jobs'

    yield put(FetchJobsDashboard.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface CreateJobRequest {
  name: string
  startLocation: string
  endLocation: string
  payment: {
    estimated: number // value in cents(15$ = 1500)
  }
  startTime: string
  endTime: string
  externalJobId: string
}

function* onCreateJob(action: AsyncAction<CreateJobRequest>) {
  try {
    const { payload } = yield call(callSecureApi, Endpoints.CreateJob, {
      apiService: ApiService.Paraworks,
      method: 'POST',
      body: {
        ...action.payload,
      },
    })

    yield put(CreateJob.success(payload))

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage =
      typeof err?.payload?.message === 'string' ? err?.payload?.message : 'Failed to create Job'

    yield put(CreateJob.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UpdateJobRequest extends CreateJobRequest {}

function* onEditJob(action: AsyncAction<Partial<UpdateJobRequest>>) {
  try {
    const { payload } = yield call(callSecureApi, Endpoints.UpdateJob, {
      apiService: ApiService.Paraworks,
      method: 'PUT',
      body: {
        ...action.payload,
      },
    })

    yield put(EditJob.success(payload))

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage =
      typeof err?.payload?.message === 'string' ? err?.payload?.message : 'Failed to edit Job'

    yield put(EditJob.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

function* onDeleteJobs(action: AsyncAction<Partial<CreateJobRequest>>) {
  try {
    const { payload } = yield call(callSecureApi, Endpoints.DeleteJobs, {
      method: 'DELETE',
      apiService: ApiService.Paraworks,
      body: {
        payload: action.payload,
      },
    })

    yield put(DeleteJobs.success(payload))

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to delete job(s)'

    yield put(DeleteJobs.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

function* onUnassignDrivers(action: AsyncAction<Partial<CreateJobRequest>>) {
  try {
    const { payload } = yield call(callSecureApi, Endpoints.UnassignDrivers, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: {
        payload: action.payload,
        cancelledBy: 'partner',
      },
    })

    yield put(UnassignDrivers.success(payload))

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to unassign driver(s)'

    yield put(UnassignDrivers.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* jobsSaga() {
  yield takeEvery(FetchJobsDashboard.type.REQUEST, onFetchJobsDashboard)
  yield takeEvery(CreateJob.type.REQUEST, onCreateJob)
  yield takeEvery(EditJob.type.REQUEST, onEditJob)
  yield takeEvery(DeleteJobs.type.REQUEST, onDeleteJobs)
  yield takeEvery(UnassignDrivers.type.REQUEST, onUnassignDrivers)
}
