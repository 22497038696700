import React, { memo } from 'react'
import { EmptyJobsDashboard } from 'src/components/jobs/dashboard/grid/EmptyJobsDashboard'
import { JobsDashboard } from 'src/components/jobs/dashboard/grid/JobsDashboard'
import { useJobModal, useConnectCartwheelModal, useUploadCSVModal } from 'src/hooks/modals'

const JobsDashboardPageComponent = () => {
  const { openConnectCartwheelModal } = useConnectCartwheelModal()
  const { openJobModal } = useJobModal()
  const { openUploadCSVModal } = useUploadCSVModal()

  return true ? (
    <JobsDashboard />
  ) : (
    <EmptyJobsDashboard
      onAddJob={openJobModal}
      onCSV={openUploadCSVModal}
      onImportCartwheel={openConnectCartwheelModal}
    />
  )
}

export const JobsDashboardPage = memo(JobsDashboardPageComponent)
