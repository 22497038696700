import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import { UploadJobsCsv } from 'src/constants/actionTypes'
import { ApiService, callSecureApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'

interface UploadJobsCsvRequest {
  file: File
}

function* onUploadJobsCsv(action: AsyncAction<UploadJobsCsvRequest>) {
  const { file } = action.payload

  try {
    const formData = new FormData()

    formData.append('file', file)

    formData.forEach((value, key) => console.log(value, key))

    yield call(callSecureApi, Endpoints.UploadJobsCsv, {
      apiService: ApiService.Paraworks,
      method: 'POST',
      body: formData,
      resetContentType: true,
      returnResponse: true,
    })

    yield put(UploadJobsCsv.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage =
      typeof err?.payload?.message === 'string'
        ? err?.payload?.message
        : 'Failed to upload jobs csv'

    yield put(UploadJobsCsv.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* jobsCsvSaga() {
  yield takeEvery(UploadJobsCsv.type.REQUEST, onUploadJobsCsv)
}
