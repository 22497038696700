import { call, put, takeEvery } from 'redux-saga/effects'
import { Action, AsyncAction } from 'src/utils/reduxUtils'
import {
  FetchUser,
  Logout,
  SetCancelationPolicy,
  SetUser,
  SetUserToken,
} from 'src/constants/actionTypes'
import { ApiService, callSecureApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { User } from 'src/interfaces/user'
import { UserService } from 'src/services/UserService'

function* onFetchUser(action: AsyncAction<void>) {
  try {
    const { payload }: { payload: User } = yield call(callSecureApi, Endpoints.FetchUser, {
      apiService: ApiService.Paraworks,
    })

    yield put(FetchUser.success())
    yield put(SetUser.request(payload))
    yield put(
      SetCancelationPolicy.success({
        cancelationPolicy: payload.partner.cancelationPolicy,
        driverCancelationPolicy: payload.partner.driverCancelationPolicy,
      }),
    )

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch user'

    yield put(FetchUser.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

function* onSetUser(action: Action<User>) {
  const user = action.payload

  yield put(SetUser.success(user))

  UserService.setStoredUser(user)
}

function* onSetUserToken(action: Action<string>) {
  const userToken = action.payload

  yield put(SetUserToken.success(userToken))

  UserService.setStoredUserToken(userToken)
}

function* onLogout() {
  yield put(SetUser.request(null))
  yield put(SetUserToken.request(null))
  yield put(Logout.success())
}

export function* userSaga() {
  yield takeEvery(FetchUser.type.REQUEST, onFetchUser)
  yield takeEvery(SetUser.type.REQUEST, onSetUser)
  yield takeEvery(SetUserToken.type.REQUEST, onSetUserToken)
  yield takeEvery(Logout.type.REQUEST, onLogout)
}
