import { Formik } from 'formik'
import { memo, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ConnectCartwheel, FetchUser } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { ConnectCartwheelFormValues } from './ConnectCartwheelForm.interface'
import { ConnectCartwheelSchema } from './ConnectCartwheelForm.validator'
import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import { FormErrorMessage } from 'src/components/shared/form/FormErrorMessage'
import tw from 'twin.macro'
import { ContactUs } from 'src/components/shared/ContactUs'
import { showToast } from 'src/utils/toast'
import { LoadingButton } from '@mui/lab'

const initialValues: ConnectCartwheelFormValues = {
  url: '',
  email: '',
  password: '',
}

interface Props {
  onSubmit(): void
}

const ConnectCartwheelFormComponent = ({ onSubmit }: Props) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleFormSubmit = useCallback(
    async (values: ConnectCartwheelFormValues) => {
      setIsLoading(true)
      setError(null)

      try {
        await createAsyncAction(dispatch, ConnectCartwheel.request(values))
        await createAsyncAction(dispatch, FetchUser.request())

        showToast('Cartwheel account was successfully connected', {
          variant: 'success',
        })

        onSubmit()
      } catch (err: unknown) {
        setError(err as string)
      } finally {
        setIsLoading(false)
      }
    },
    [onSubmit],
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={ConnectCartwheelSchema}
    >
      {({ handleSubmit }) => (
        <div>
          <FormikTextField
            name="url"
            css={tw`w-full mb-6`}
            label="Cartwheel URL"
            placeholder="e.g. para.cartwheel.tech"
          />
          <FormikTextField
            name="email"
            css={tw`w-full mb-6`}
            label="Email"
            placeholder="Enter your email"
          />
          <FormikTextField
            name="password"
            css={tw`w-full mb-6`}
            label="Password"
            type="password"
            placeholder="Enter your password"
          />
          <FormErrorMessage css={tw`w-full mb-6`} error={error} />
          <LoadingButton
            css={tw`w-full mb-8 mt-4`}
            variant="contained"
            loading={isLoading}
            onClick={() => {
              handleSubmit()
            }}
          >
            Connect
          </LoadingButton>
          <ContactUs text="Need help?" />
        </div>
      )}
    </Formik>
  )
}

export const ConnectCartwheelForm = memo(ConnectCartwheelFormComponent)
