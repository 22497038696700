import {
  SetConnectCartwheelModal,
  SetJobModal,
  SetUploadCSVModal,
  SetCancelationPolicyModal,
  Logout,
  SetDeleteJobsModal,
  SetUnassignDriversModal,
  SetDeleteCartwheelAccountModal,
  SetCartwheelEditPayoutModal,
} from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { ModalsState, ModalState } from './types'

const initialModalState: ModalState = {
  isOpen: false,
  showBackButton: false,
}

const getInitialState = (): ModalsState => ({
  cartwheel: initialModalState,
  cancelationPolicy: initialModalState,
  uploadCSV: initialModalState,
  job: {
    ...initialModalState,
    job: undefined,
  },
  deleteJob: {
    ...initialModalState,
    jobIds: [],
  },
  unassignDriver: {
    ...initialModalState,
    jobIds: [],
  },
  deleteCartwheelAccount: initialModalState,
  cartwheelEditPayout: {
    ...initialModalState,
    jobIds: [],
  },
})

export const modalsReducer = (
  state: ModalsState = getInitialState(),
  action: Action<any>,
): ModalsState => {
  switch (action.type) {
    case SetConnectCartwheelModal.type.SUCCESS:
      return {
        ...state,
        cartwheel: {
          ...(action.payload.isOpen ? action.payload : getInitialState().cartwheel),
        },
      }
    case SetJobModal.type.SUCCESS:
      return {
        ...state,
        job: {
          ...(action.payload.isOpen ? action.payload : getInitialState().job),
        },
      }
    case SetDeleteJobsModal.type.SUCCESS:
      return {
        ...state,
        deleteJob: {
          ...(action.payload.isOpen ? action.payload : getInitialState().deleteJob),
        },
      }
    case SetUnassignDriversModal.type.SUCCESS:
      return {
        ...state,
        unassignDriver: {
          ...(action.payload.isOpen ? action.payload : getInitialState().unassignDriver),
        },
      }
    case SetUploadCSVModal.type.SUCCESS:
      return {
        ...state,
        uploadCSV: {
          ...(action.payload.isOpen ? action.payload : getInitialState().uploadCSV),
        },
      }
    case SetCancelationPolicyModal.type.SUCCESS:
      return {
        ...state,
        cancelationPolicy: {
          ...(action.payload.isOpen ? action.payload : getInitialState().cancelationPolicy),
        },
      }
    case SetDeleteCartwheelAccountModal.type.SUCCESS:
      return {
        ...state,
        deleteCartwheelAccount: {
          ...(action.payload.isOpen ? action.payload : getInitialState().deleteCartwheelAccount),
        },
      }
    case SetCartwheelEditPayoutModal.type.SUCCESS:
      return {
        ...state,
        cartwheelEditPayout: {
          ...(action.payload.isOpen ? action.payload : getInitialState().cartwheelEditPayout),
        },
      }
    case Logout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
