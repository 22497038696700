import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { createContext, MouseEvent } from 'react'
import { JobStatus } from 'src/constants/jobs/enums'
import { Job } from 'src/interfaces/jobs'

export interface IJobsDashboardContext {
  includedColumns: Array<string>
  excludedColumns: Array<string>
  pinnedColumns: Array<string>
  handleAddExcludedColumn(column: string): void
  handleRemoveExcludedColumn(column: string): void
  handleAddPinnedColumn(column: string): void
  handleRemovePinnedColumn(column: string): void

  filterDateAfter: string
  handleChangeFilterDateAfter(value: string): void

  selectedRows: GridRowSelectionModel
  setSelectedRows(rowSelectionModel: GridRowSelectionModel): void

  selectedJobs: Array<Job>

  statusFilter: Array<JobStatus>
  setStatusFilter(statusFilter: Array<JobStatus>): void

  searchQuery: string
  setSearchQuery(value: string): void

  refreshJobs(): void

  // edit action
  actionAnchor: null | HTMLElement
  selectedJob: Job | null
  openJobsDashboardItemAction(event: MouseEvent<HTMLButtonElement>, job: Job): void
  closeJobsDashboardItemAction(): void
}

export const JobsDashboardContext = createContext<IJobsDashboardContext>({
  includedColumns: [],
  excludedColumns: [],
  pinnedColumns: [],
  handleAddExcludedColumn: () => {},
  handleRemoveExcludedColumn: () => {},
  handleAddPinnedColumn: () => {},
  handleRemovePinnedColumn: () => {},

  filterDateAfter: '',
  handleChangeFilterDateAfter: () => {},

  selectedRows: [],
  setSelectedRows: () => {},

  selectedJobs: [],

  statusFilter: [],
  setStatusFilter: () => {},

  searchQuery: '',
  setSearchQuery: () => {},

  refreshJobs: () => {},

  actionAnchor: null,
  selectedJob: null,
  openJobsDashboardItemAction: () => {},
  closeJobsDashboardItemAction: () => {},
})
