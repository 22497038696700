import Menu from '@mui/material/Menu'
import Button from '@mui/material/Button'
import React, { memo, useCallback, useContext, useMemo, useState } from 'react'
import tw from 'twin.macro'
import {
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Typography,
} from '@mui/material'
import { CaretDownMinor, CaretUpMinor } from '@shopify/polaris-icons'
import { colors } from 'src/theme'
import { JobStatus } from 'src/constants/jobs/enums'
import { JobsDashboardContext } from '../JobsDashboard.context'

enum JobStatusExtraValue {
  All = 'all',
}

type JobStatusValue = JobStatus | JobStatusExtraValue

interface JobStatusItem {
  value: JobStatusValue
  label: string
}

const jobStatusItems: Array<JobStatusItem> = [
  {
    value: JobStatusExtraValue.All,
    label: 'All Statuses',
  },
  {
    value: JobStatus.Active,
    label: 'Active',
  },
  {
    value: JobStatus.Full,
    label: 'Filled',
  },
  {
    value: JobStatus.Canceled,
    label: 'Canceled',
  },
  {
    value: JobStatus.Completed,
    label: 'Completed',
  },
]

const JobStatusSelectComponent = () => {
  const { statusFilter, setStatusFilter } = useContext(JobsDashboardContext)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleToggle = useCallback(
    (value: JobStatusValue) => {
      if (value === JobStatusExtraValue.All) {
        if (statusFilter.length === Object.values(JobStatus).length) {
          setStatusFilter([])
        } else {
          setStatusFilter(Object.values(JobStatus))
        }

        return
      }

      const currentIndex = statusFilter.indexOf(value)

      if (currentIndex === -1) {
        setStatusFilter([...statusFilter, value])
      } else {
        setStatusFilter([
          ...statusFilter.slice(0, currentIndex),
          ...statusFilter.slice(currentIndex + 1),
        ])
      }
    },
    [statusFilter],
  )

  const getItemChecked = useCallback(
    (jobStatusValue: JobStatusValue) => {
      if (jobStatusValue === JobStatusExtraValue.All) {
        return statusFilter.length === Object.keys(JobStatus).length
      }

      return statusFilter.includes(jobStatusValue)
    },
    [statusFilter],
  )

  return (
    <>
      <Button
        id="basic-button"
        variant="outlined"
        color="secondary"
        aria-controls={isMenuOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={handleClick}
        css={[tw`rounded-r-none border border-gray-300`, isMenuOpen && tw`bg-gray-100`]}
        endIcon={
          isMenuOpen ? (
            <SvgIcon>
              <CaretUpMinor />
            </SvgIcon>
          ) : (
            <SvgIcon>
              <CaretDownMinor />
            </SvgIcon>
          )
        }
      >
        <Typography fontWeight={500} variant="body2">
          Job Status
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        css={tw`mt-2 rounded-xl`}
      >
        <Typography fontWeight={600} variant="body2" color={colors.GRAY_COOL_GRAY} css={tw`m-4`}>
          SELECT STATUSES
        </Typography>
        <Divider />
        <List>
          {jobStatusItems.map(({ label, value }) => {
            const labelId = `checkbox-list-label-${value}`

            return (
              <ListItem css={tw`w-[210px] h-10`} key={value} disablePadding>
                <ListItemButton role={undefined} onClick={() => handleToggle(value)} dense>
                  <ListItemIcon css={tw`min-w-0 mr-2`}>
                    <Checkbox
                      edge="start"
                      checked={getItemChecked(value)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={<Typography color={colors.GRAY_COOL_GRAY}>{label}</Typography>}
                  />
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </Menu>
    </>
  )
}

export const JobStatusSelect = memo(JobStatusSelectComponent)
