import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FetchUser } from 'src/constants/actionTypes'
import { getUserToken } from 'src/selectors/auth'
import { useAsyncDispatchWithErrorToast } from './useAsyncDispatchWithErrorToast'

export const initializeApplication = () => {
  const { asyncDispatchWithErrorToast } = useAsyncDispatchWithErrorToast()

  const userToken = useSelector(getUserToken)

  useEffect(() => {
    if (!userToken) {
      return
    }

    asyncDispatchWithErrorToast(FetchUser.request())
  }, [])
}
