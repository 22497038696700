import { Routes, Route, Navigate } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import { LoginPage } from 'src/pages/auth/LoginPage'
import React from 'react'

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path={ROUTES.LOGIN_URL} element={<LoginPage />} />
      <Route path="*" element={<Navigate to={ROUTES.LOGIN_URL} />} />
    </Routes>
  )
}
