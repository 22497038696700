import { memo, useCallback, useEffect, useState } from 'react'
import { Tabs, Typography, Tab } from '@mui/material'
import tw from 'twin.macro'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import { useSelector } from 'react-redux'
import { getUser } from 'src/selectors/auth'
import { useConnectCartwheelModal } from 'src/hooks/modals'

enum JobsDashboardTabs {
  Jobs = 'jobs',
  Cartwheel = 'cartwheel',
}

const jobsDashboardTabOptions = [
  {
    value: JobsDashboardTabs.Jobs,
    route: ROUTES.JOBS_DASHBOARD,
  },
  {
    value: JobsDashboardTabs.Cartwheel,
    route: ROUTES.IMPORT_CARTWHEEL_URL,
  },
]

const JobsPageHeaderComponent = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { openConnectCartwheelModal } = useConnectCartwheelModal()

  const user = useSelector(getUser)

  const getTabValue = useCallback((pathname: string) => {
    for (const jobsDashboardTabOption of jobsDashboardTabOptions) {
      if (pathname.includes(jobsDashboardTabOption.route)) {
        return jobsDashboardTabOption.value
      }
    }

    return JobsDashboardTabs.Jobs
  }, [])

  const [activeTab, setActiveTab] = useState<JobsDashboardTabs>(getTabValue(location.pathname))

  useEffect(() => {
    setActiveTab(getTabValue(location.pathname))
  }, [location.pathname])

  const handleChange = useCallback(
    (_: any, value: JobsDashboardTabs) => {
      if (value === JobsDashboardTabs.Cartwheel) {
        if (!user?.partner.cartwheel) {
          openConnectCartwheelModal()
          return
        }
      }

      const route = jobsDashboardTabOptions.find(
        (jobsDashboardTabOption) => jobsDashboardTabOption.value === value,
      )

      if (!route) {
        return
      }

      navigate(route.route)
    },
    [user],
  )

  return (
    <div css={tw`flex items-center flex-row`}>
      <Typography css={tw`mr-10`} variant="h4">
        Jobs Dashboard
      </Typography>
      <Tabs value={activeTab} onChange={handleChange} css={tw`border-b flex-1`}>
        <Tab css={tw`[text-transform: none]`} value={JobsDashboardTabs.Jobs} label="Main Board" />
        <Tab
          css={tw`[text-transform: none]`}
          value={JobsDashboardTabs.Cartwheel}
          label="Import from Cartwheel"
        />
      </Tabs>
    </div>
  )
}

export const JobsPageHeader = memo(JobsPageHeaderComponent)
