import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { CartwheelGridHeader } from './CartwheelGridHeader'
import { ICartwheelDashboardContext, CartwheelDashboardContext } from './CartwheelDashboard.context'
import { useDispatch } from 'react-redux'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { FetchCartwheelJobsDashboard } from 'src/constants/actionTypes'
import { CartwheelJob } from 'src/interfaces/jobs'
import { useGridPagination } from 'src/utils/hooks/grid/useGridPagination'
import { cartwheelJobsDashboardPageSizeOptions } from 'src/constants/jobs/cartwheelJobsDashboardGrid'
import { CartwheelGrid } from './CartwheelGrid'
import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { DeleteCartwheelAccountModal } from '../connect-cartwheel/DeleteCartwheelAccountModal'
import { showToast } from 'src/utils/toast'
import { CartwheelEditPayoutModal } from '../CartwheelEditPayoutModal'

const CartwheelDashboardComponent = () => {
  const dispatch = useDispatch()

  const { paginationModel, setPaginationModel } = useGridPagination(
    cartwheelJobsDashboardPageSizeOptions,
  )

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [cartwheelJobs, setCartwheelJobs] = useState<Array<CartwheelJob>>([])
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([])

  const fetchCartwheelJobs = useCallback(async () => {
    setIsLoading(true)

    try {
      const payload: { jobs: Array<CartwheelJob> } = await createAsyncAction(
        dispatch,
        FetchCartwheelJobsDashboard.request({}),
      )

      setCartwheelJobs(payload.jobs)
    } catch (err: unknown) {
      showToast(err as string, {
        variant: 'error',
      })
    }

    setIsLoading(false)
  }, [])

  useEffect(() => {
    fetchCartwheelJobs()
  }, [])

  const selectedCartwheelJobs = useMemo<Array<CartwheelJob>>(() => {
    return selectedRows.reduce<Array<CartwheelJob>>((arr, selectedRowId) => {
      const job = cartwheelJobs.find((item) => item.orderId === selectedRowId)

      if (job) {
        arr.push(job)
      }

      return arr
    }, [])
  }, [cartwheelJobs, selectedRows])

  const filteredCartwheelJobs = useMemo<Array<CartwheelJob>>(() => {
    if (!searchQuery) {
      return cartwheelJobs
    }

    return cartwheelJobs.filter((cartwheelJob) => cartwheelJob.orderId === searchQuery)
  }, [cartwheelJobs, searchQuery])

  const handlePartialEditCartwheelJobs = useCallback(
    (valueList: Array<Partial<CartwheelJob>>) => {
      const updatedCartwheelJobs = cartwheelJobs.reduce<Array<CartwheelJob>>(
        (acc, cartwheelJob) => {
          const newJobValues = valueList.find((value) => value.orderId === cartwheelJob.orderId)

          if (!newJobValues) {
            acc.push(cartwheelJob)
            return acc
          }

          acc.push({
            ...cartwheelJob,
            ...newJobValues,
          })
          return acc
        },
        [],
      )

      setCartwheelJobs(updatedCartwheelJobs)
    },
    [cartwheelJobs],
  )

  const cartwheelGridContextValue = useMemo<ICartwheelDashboardContext>(() => {
    return {
      searchQuery,
      setSearchQuery,

      refreshJobs: fetchCartwheelJobs,
      isRefreshJobsLoading: isLoading,

      selectedRows,
      setSelectedRows,

      selectedCartwheelJobs,

      partialEditCartwheelJobs: handlePartialEditCartwheelJobs,
    }
  }, [
    searchQuery,
    fetchCartwheelJobs,
    isLoading,
    selectedRows,
    selectedCartwheelJobs,
    handlePartialEditCartwheelJobs,
  ])

  return (
    <CartwheelDashboardContext.Provider value={cartwheelGridContextValue}>
      <div>
        <CartwheelGridHeader />
        <CartwheelGrid
          jobs={filteredCartwheelJobs}
          isLoading={isLoading}
          paginationModel={paginationModel}
          pageSizeOptions={cartwheelJobsDashboardPageSizeOptions}
          rowSelectionModel={selectedRows}
          onPaginationModelChange={setPaginationModel}
          onRowSelectionModelChange={setSelectedRows}
        />
      </div>
      <DeleteCartwheelAccountModal />
      <CartwheelEditPayoutModal />
    </CartwheelDashboardContext.Provider>
  )
}

export const CartwheelDashboard = memo(CartwheelDashboardComponent)
