import { memo } from 'react'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import { contactUs } from 'src/utils/contactUs'

interface Props {
  text: string
  css?: any
}

const ContactUsComponent = ({ text, ...rest }: Props) => (
  <Typography css={[tw`flex flex-row justify-center`]} color={colors.GRAY_DARK_COOL} {...rest}>
    {text} &nbsp;
    <Typography
      css={tw`cursor-pointer`}
      onClick={contactUs}
      fontWeight={600}
      color={colors.BRAND_BLUE}
    >
      Contact us
    </Typography>
  </Typography>
)

export const ContactUs = memo(ContactUsComponent)
