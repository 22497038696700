import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridPaginationModel,
  GridPinnedColumns,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro'
import React, { memo, useMemo } from 'react'
import { GridTable } from 'src/components/shared/grid/GridTable'
import { jobsDashboardColumns } from 'src/constants/jobs/jobDashboardGrid'
import { Job } from 'src/interfaces/jobs'
import tw from 'twin.macro'

const handleGetRowId = (row: Job) => row.id

interface Props {
  jobs: Array<Job>
  pinnedColumns: Array<string>
  excludedColumns: Array<string>
  isLoading: boolean
  paginationModel: GridPaginationModel
  pageSizeOptions: Array<number>
  rowCount: number
  rowSelectionModel: GridRowSelectionModel
  onPaginationModelChange(paginationModel: GridPaginationModel): void
  onRowSelectionModelChange(rowSelectionModel: GridRowSelectionModel): void
}

const JobsGridComponent = ({
  jobs,
  pinnedColumns,
  excludedColumns,
  isLoading,
  paginationModel,
  pageSizeOptions,
  rowCount,
  rowSelectionModel,
  onPaginationModelChange,
  onRowSelectionModelChange,
}: Props) => {
  const gridColumns = useMemo(() => {
    return jobsDashboardColumns.filter((column) => !excludedColumns.includes(column.field))
  }, [excludedColumns])

  const gridPinnedColumns = useMemo<GridPinnedColumns>(() => {
    const left = [...pinnedColumns]

    if (pinnedColumns.length) {
      left.unshift(GRID_CHECKBOX_SELECTION_COL_DEF.field)
    }

    return {
      right: ['actions'],
      left,
    }
  }, [pinnedColumns])

  return (
    <div css={tw`w-full h-[600px] mt-4`}>
      <GridTable
        rows={jobs}
        columns={gridColumns}
        pinnedColumns={gridPinnedColumns}
        checkboxSelection
        rowSelectionModel={rowSelectionModel}
        loading={isLoading}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        rowCount={rowCount}
        onPaginationModelChange={onPaginationModelChange}
        pageSizeOptions={pageSizeOptions}
        getRowId={handleGetRowId}
        onRowSelectionModelChange={onRowSelectionModelChange}
      />
    </div>
  )
}

export const JobsGrid = memo(JobsGridComponent)
