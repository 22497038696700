import { Formik } from 'formik'
import { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CreateJob, EditJob } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { JobFormValues } from './JobForm.interface'
import { AddJobSchema } from './JobForm.validator'
import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import { FormErrorMessage } from 'src/components/shared/form/FormErrorMessage'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import { InfoContainer } from 'src/components/shared/InfoContainer'
import { FormikDatePicker } from 'src/components/shared/form/formik/FormikDatePicker'
import { FormikTimePicker } from 'src/components/shared/form/formik/FormikTimePicker'
import tw from 'twin.macro'
import InputAdornment from '@mui/material/InputAdornment'
import { CancelationPolicyInfoContainer } from '../cancelation-policy/CancelationPolicyInfoContainer'
import { v4 as uuid } from 'uuid'
import { Job } from 'src/interfaces/jobs'
import dayjs from 'dayjs'
import { showToast } from 'src/utils/toast'
import { JobsDashboardContext } from '../grid/JobsDashboard.context'
import { LoadingButton } from '@mui/lab'

const getInitialValues = (job?: Job): JobFormValues => {
  return {
    externalJobId: job?.externalJobId || '',
    name: job?.name || '',
    startDate: dayjs(job?.startTimeRaw),
    startTime: dayjs(job?.startTimeRaw),
    endDate: dayjs(job?.endTimeRaw),
    endTime: dayjs(job?.endTimeRaw),
    startLocation: job?.startLocation || '',
    endLocation: job?.endLocation || '',
    price: job?.payment?.estimated ? `${job.payment.estimated / 100}` : '',
  }
}

interface Props {
  job?: Job
  onSubmit(): void
}

const DATE_FORMAT = 'YYYY-MM-DD'
const TIME_FORMAT = 'HH:MM'

export const JobForm = ({ job, onSubmit }: Props) => {
  const dispatch = useDispatch()

  const { refreshJobs } = useContext(JobsDashboardContext)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleFormSubmit = async (values: JobFormValues) => {
    setIsLoading(true)
    setError(null)

    try {
      const requestObject = {
        externalJobId: values.externalJobId || uuid(),
        name: values.name,
        startLocation: values.startLocation,
        endLocation: values.endLocation,
        startTime: `${values.startDate?.format(DATE_FORMAT)}T${values.startTime?.format(
          TIME_FORMAT,
        )}`,
        endTime: `${values.endDate?.format(DATE_FORMAT)}T${values.endTime?.format(TIME_FORMAT)}`,
        payment: {
          estimated: 100 * Number(values.price),
        },
      }

      if (job) {
        await createAsyncAction(dispatch, EditJob.request({ ...requestObject, id: job.id }))
      } else {
        await createAsyncAction(dispatch, CreateJob.request(requestObject))
      }

      refreshJobs()

      showToast(`Job has been ${job ? 'changed' : 'created'}`, {
        variant: 'success',
      })

      onSubmit()
    } catch (err: any) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Formik
      initialValues={{ ...getInitialValues(job) }}
      onSubmit={handleFormSubmit}
      validationSchema={AddJobSchema}
    >
      {({ handleSubmit }) => (
        <div>
          <FormikTextField name="externalJobId" css={tw`w-full mb-6`} label="Job ID (optional)" />
          <FormikTextField name="name" css={tw`w-full mb-2`} label="Job Name*" />
          <InfoContainer css={tw`mb-6`}>
            <Typography color={colors.GRAY_DARK_COOL}>
              Job name will be shown to the driver and should include the name of the
              restaurant/store
            </Typography>
          </InfoContainer>
          <FormikTextField
            name="price"
            css={tw`w-full mb-6`}
            label="Price*"
            type="number"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
          <div css={tw`flex flex-row space-x-2  mb-6`}>
            <FormikDatePicker<JobFormValues> name="startDate" label="Start Date*" />
            <FormikTimePicker name="startTime" label="Start Time*" />
          </div>
          <div css={tw`flex flex-row space-x-2  mb-6`}>
            <FormikDatePicker name="endDate" label="End Date*" />
            <FormikTimePicker name="endTime" label="End Time*" />
          </div>
          <FormikTextField name="startLocation" css={tw`w-full mb-6`} label="Start Location*" />
          <FormikTextField name="endLocation" css={tw`w-full mb-6`} label="End Location*" />
          {!job && <CancelationPolicyInfoContainer />}
          <FormErrorMessage css={tw`mb-6`} error={error} />
          <LoadingButton
            css={tw`w-32 mt-4`}
            variant="contained"
            loading={isLoading}
            onClick={() => handleSubmit()}
          >
            Submit
          </LoadingButton>
        </div>
      )}
    </Formik>
  )
}
