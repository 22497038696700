import Menu from '@mui/material/Menu'
import Button from '@mui/material/Button'
import React, { memo, useCallback, useMemo } from 'react'
import tw from 'twin.macro'
import { SvgIcon, Typography } from '@mui/material'
import { CalendarMajor, CaretDownMinor, CaretUpMinor } from '@shopify/polaris-icons'
import { DateCalendar } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'

interface Props {
  value: string
  format: string
  onChange(value: string): void
}

const DateCalendarSelectComponent = ({ value, format, onChange }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleChange = useCallback(
    (value: Dayjs | null) => {
      if (!value) {
        handleClose()
        return
      }

      onChange(value.format(format))
      handleClose()
    },
    [onChange],
  )

  const calendarValue = useMemo(() => {
    return dayjs(value, format)
  }, [value, format])

  return (
    <>
      <Button
        id="basic-button"
        variant="outlined"
        color="secondary"
        aria-controls={isMenuOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={handleClick}
        css={[tw`rounded-none border-x-0 border border-gray-300`, isMenuOpen && tw`bg-gray-100`]}
        startIcon={
          <SvgIcon>
            <CalendarMajor />
          </SvgIcon>
        }
        endIcon={
          isMenuOpen ? (
            <SvgIcon>
              <CaretUpMinor />
            </SvgIcon>
          ) : (
            <SvgIcon>
              <CaretDownMinor />
            </SvgIcon>
          )
        }
      >
        <Typography fontWeight={500} variant="body2">
          {dayjs(value, format).format('LL')}
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        css={tw`mt-2 rounded-xl`}
      >
        <DateCalendar value={calendarValue} onChange={handleChange} />
      </Menu>
    </>
  )
}

export const DateCalendarSelect = memo(DateCalendarSelectComponent)
