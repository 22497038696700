import { createTheme } from '@mui/material/styles'

export const colors = {
  // Brand
  BRAND_BLUE: '#0056FF',
  BRAND_GREEN: '#51F7D9',
  BRAND_YELLOW: '#FFD442',
  BRAND_NAVY: '#181829',

  // Grayscale
  GRAY_BLACK: '#000000',
  GRAY_DARK_COOL: '#655E7C',
  GRAY_COOL_GRAY: '#847E96',
  GRAY_MID_COOL: '#DAD9E0',
  GRAY_LIGHT_COOL: '#F6F5F7',
  GRAY_PALE_COOL: '#FAFAFB',
  GRAY_WHITE: '#FFFFFF',

  // Form
  ERROR: '#E33914',
  SUCCESS: '#04C523',
}

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.BRAND_BLUE,
    },
    secondary: {
      main: colors.BRAND_NAVY,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: { height: '44px', borderRadius: '4px', textTransform: 'none', fontSize: 14 },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: { borderRadius: '4px' },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: { paddingTop: '0px' },
        input: { paddingTop: '11px', paddingBottom: '11px' },
        multiline: { paddingBottom: '11px' },
      },
    },
  },
  typography: {
    fontFamily: 'SF Pro Display',
    h1: {
      fontSize: '64px',
      lineHeight: '64px',
      letterSpacing: '-0.02em',
      fontWeight: 700,
      color: colors.BRAND_NAVY,
    },
    h2: {
      fontSize: '48px',
      lineHeight: '48px',
      letterSpacing: '-0.02em',
      fontWeight: 700,
      color: colors.BRAND_NAVY,
    },
    h3: {
      fontSize: '40px',
      lineHeight: '40px',
      letterSpacing: '-0.02em',
      fontWeight: 700,
      color: colors.BRAND_NAVY,
    },
    h4: {
      fontSize: '32px',
      lineHeight: '32px',
      letterSpacing: '-0.02em',
      fontWeight: 700,
      color: colors.BRAND_NAVY,
    },
    h5: {
      fontSize: '24px',
      lineHeight: '24px',
      letterSpacing: '-0.02em',
      fontWeight: 600,
      color: colors.BRAND_NAVY,
    },
    h6: {},
    // Light / Subtitle / Semibold
    subtitle1: {
      fontSize: '18px',
      lineHeight: '20px',
      letterSpacing: '-0.02em',
      fontWeight: 600,
      color: colors.BRAND_NAVY,
    },
    // Light / Pretext / Semibold
    subtitle2: {
      fontSize: '16px',
      lineHeight: '16px',
      fontWeight: 500,
      color: colors.BRAND_NAVY,
    },
    // Light / Body / Regular
    body1: {
      fontSize: '16px',
      lineHeight: '20px',
      color: colors.BRAND_NAVY,
    },
    // Light / Body Small / Regular
    body2: {
      fontSize: '14px',
      lineHeight: '20px',
      color: colors.BRAND_NAVY,
    },
    button: {
      fontSize: '16px',
      lineHeight: '16px',
      fontWeight: 500,
      color: colors.BRAND_NAVY,
    },
    // Light / Label Small / Medium
    caption: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 500,
      letterSpacing: '0.02em',
      color: colors.BRAND_NAVY,
    },
    overline: {},
  },
})
