import { User } from 'src/interfaces/user'
import { State } from '../reducers/types'

export const getUser = (state: State): User | null => state.auth.user

export const getUserToken = (state: State): string | null => state.auth.userToken

export const getIsUserInitialized = (state: State): boolean => state.auth.isUserInitialized

interface CancelationPolicies {
  cancelationPolicy?: string
  driverCancelationPolicy?: string
}

export const getCancelationPolicies = (state: State): CancelationPolicies => ({
  cancelationPolicy: state.auth.user?.partner.cancelationPolicy || undefined,
  driverCancelationPolicy: state.auth.user?.partner.driverCancelationPolicy || undefined,
})
