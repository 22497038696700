import Button from '@mui/material/Button'
import React, { memo, useCallback, useContext, useMemo } from 'react'
import { SvgIcon } from '@mui/material'
import { ChevronDownMinor, CustomerMinusMajor, DeleteMinor } from '@shopify/polaris-icons'
import { colors } from 'src/theme'
import { MenuActionItem } from 'src/interfaces/ui'
import { ActionMenu } from 'src/components/shared/ActionMenu'
import { JobsDashboardContext } from '../JobsDashboard.context'
import { useDeleteJobsModal, useUnassignDriversModal } from 'src/hooks/modals'

const JobsDashboardBatchActionButtonComponent = () => {
  const { selectedJobs } = useContext(JobsDashboardContext)

  const { openDeleteJobsModal } = useDeleteJobsModal()
  const { openUnassignDriversModal } = useUnassignDriversModal()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const isOpen = Boolean(anchorEl)

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const selectedDriverJobIds = useMemo<string[]>(
    () =>
      selectedJobs.reduce<string[]>((arr, item) => {
        if (item.driverJobId) {
          arr.push(item.driverJobId)
        }

        return arr
      }, []),
    [selectedJobs],
  )

  const handleUnassignDriver = useCallback(() => {
    if (!selectedDriverJobIds?.length) {
      return
    }

    openUnassignDriversModal(selectedDriverJobIds)

    handleClose()
  }, [selectedDriverJobIds, useUnassignDriversModal])

  const handleDeleteJobs = useCallback(() => {
    if (!selectedJobs?.length) {
      return
    }

    openDeleteJobsModal(selectedJobs.map((item) => item.id))

    handleClose()
  }, [selectedJobs, openDeleteJobsModal])

  const actionItems = useMemo<MenuActionItem[]>(() => {
    const res: MenuActionItem[] = [
      {
        label: 'Delete Job(s)',
        Icon: DeleteMinor,
        color: colors.ERROR,
        onClick: handleDeleteJobs,
      },
    ]

    if (selectedDriverJobIds.length) {
      res.unshift({
        label: 'Unassign driver(s)',
        Icon: CustomerMinusMajor,
        onClick: handleUnassignDriver,
      })
    }

    return res
  }, [selectedDriverJobIds, handleDeleteJobs, handleUnassignDriver])

  return (
    <>
      <Button
        id="basic-button"
        variant="outlined"
        aria-controls={isOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
        disabled={!selectedJobs.length}
        endIcon={
          <SvgIcon>
            <ChevronDownMinor
              color={!selectedJobs.length ? colors.GRAY_MID_COOL : colors.BRAND_BLUE}
            />
          </SvgIcon>
        }
      >
        Action
      </Button>
      <ActionMenu
        anchorEl={anchorEl}
        isOpen={isOpen}
        items={actionItems}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      />
    </>
  )
}

export const JobsDashboardBatchActionButton = memo(JobsDashboardBatchActionButtonComponent)
