import { useDispatch } from 'react-redux'
import { Logout } from 'src/constants/actionTypes'
import { ROUTES } from 'src/constants/routes'
import { SecureLeftMenuItem } from './SecureLeftMenuItem'
import { useCallback, useState } from 'react'
import tw from 'twin.macro'
import { css } from '@emotion/react'
import { colors } from 'src/theme'
import {
  // CustomersMajor,
  ExitMajor,
  ShipmentMajor,
} from '@shopify/polaris-icons'
import { AppLogoIcon } from 'src/components/shared/icons/AppLogoIcon'
import { useNavigate } from 'react-router-dom'

export const SecureLeftMenu = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isHovered, setIsHovered] = useState<boolean>(false)

  const handleLogout = useCallback(() => {
    dispatch(Logout.request())
  }, [])

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false)
  }, [])

  const handleNavigateHome = useCallback(() => {
    navigate(ROUTES.BASE_URL)
  }, [])

  return (
    <div
      css={[
        styles.root,
        tw`w-[72px] py-4 h-full absolute left-0 top-0 z-10 transition-all duration-300 overflow-hidden`,
        isHovered ? tw`w-[188px]` : null,
      ]}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div css={tw`pl-5 mb-[100px] cursor-pointer`} onClick={handleNavigateHome}>
        <AppLogoIcon color={colors.BRAND_BLUE} />
      </div>
      <SecureLeftMenuItem
        route={ROUTES.JOBS_URL}
        label="Jobs Dashboard"
        Icon={ShipmentMajor}
        isHovered={isHovered}
      />
      {/* <SecureLeftMenuItem
        route={ROUTES.ACCOUNT_URL}
        label="Account"
        Icon={CustomersMajor}
        isHovered={isHovered}
      /> */}
      <SecureLeftMenuItem
        label="Log Out"
        Icon={ExitMajor}
        isHovered={isHovered}
        onClick={handleLogout}
      />
    </div>
  )
}

const styles = {
  root: css({
    boxShadow: '8px 0px 16px rgba(0, 0, 0, 0.02)',
    backgroundColor: colors.GRAY_WHITE,
  }),
}
