import { GridValueFormatterParams } from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import { formatCurrencyFromCents } from './transformers'

class GridFormatters {
  formatLongDate(params: GridValueFormatterParams): string {
    return dayjs(params.value).format('LLL')
  }

  formatCurrency(params: GridValueFormatterParams): string {
    return formatCurrencyFromCents(params.value)
  }

  addCurrencySign(params: GridValueFormatterParams): string {
    return `$${params.value}`
  }
}

export const gridFormatters = new GridFormatters()
