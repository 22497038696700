import React, { memo } from 'react'
import { JobsPageHeader } from 'src/components/jobs/JobsPageHeader'
import { SecurePage } from 'src/components/layout/SecurePage'
import { Outlet } from 'react-router-dom'
import { ConnectCartwheelModal } from 'src/components/jobs/cartwheel/connect-cartwheel/ConnectCartwheelModal'
import { UploadCSVModal } from 'src/components/shared/upload-csv/UploadCSVModal'
import tw from 'twin.macro'

const JobsPageComponent = () => {
  return (
    <SecurePage>
      <div css={tw`w-full px-12 py-8`}>
        <JobsPageHeader />
        <Outlet />

        <ConnectCartwheelModal />
        <UploadCSVModal />
      </div>
    </SecurePage>
  )
}

export const JobsPage = memo(JobsPageComponent)
