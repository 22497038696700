import tw from 'twin.macro'
import { Button, ButtonProps, SvgIcon } from '@mui/material'
import { memo } from 'react'
import { MobileBackArrowMajor, MobileCancelMajor } from '@shopify/polaris-icons'

interface Props extends Omit<ButtonProps, 'startIcon' | 'endIcon'> {
  icon?: 'close' | 'back'
}

const CloseButtonComponent = ({ icon, ...rest }: Props) => {
  return (
    <Button
      css={tw`w-8 h-8 rounded-2xl flex justify-center items-center min-w-0`}
      color="secondary"
      {...rest}
    >
      <SvgIcon css={tw`w-4 h-4 flex justify-center`}>
        {icon === 'back' ? <MobileBackArrowMajor /> : <MobileCancelMajor />}
      </SvgIcon>
    </Button>
  )
}

export const CloseButton = memo(CloseButtonComponent)
