import { Formik } from 'formik'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { LoginEmailPassword } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { LoginFormValues } from './LoginForm.interface'
import { LoginSchema } from './LoginForm.validator'
import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import { FormErrorMessage } from 'src/components/shared/form/FormErrorMessage'
import tw from 'twin.macro'
import { ContactUs } from 'src/components/shared/ContactUs'
import { LoadingButton } from '@mui/lab'

const initialValues: LoginFormValues = {
  email: '',
  password: '',
}

export const LoginForm = () => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleFormSubmit = async (values: LoginFormValues) => {
    setIsLoading(true)
    setError(null)

    try {
      await createAsyncAction(dispatch, LoginEmailPassword.request(values))
    } catch (err: any) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={LoginSchema}
    >
      {({ handleSubmit }) => (
        <div>
          <FormikTextField name="email" css={tw`w-full mb-6`} label="Email Address" />
          <FormikTextField name="password" css={tw`w-full mb-6`} type="password" label="Password" />
          <FormErrorMessage css={tw`mb-6`} error={error} />
          <LoadingButton
            css={tw`w-full mt-4 mb-8`}
            variant="contained"
            loading={isLoading}
            onClick={() => handleSubmit()}
          >
            Sign In
          </LoadingButton>
          <ContactUs text="Need an account?" />
        </div>
      )}
    </Formik>
  )
}
