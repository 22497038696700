import { GridColDef, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid-pro'
import { gridFormatters } from 'src/utils/grid'
import { JobsDashboardItemActionButton } from 'src/components/jobs/dashboard/grid/action/JobsDashboardItemActionButton'
import { Job } from 'src/interfaces/jobs'
import { JobPaymentStatus, JobStatusFullList } from './enums'

export const jobsDashboardColumns: Array<GridColDef> = [
  {
    field: 'externalJobId',
    headerName: 'Para Order ID',
    width: 120,
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'Order Status',
    width: 120,
    sortable: false,
    valueFormatter: (params: GridValueFormatterParams<JobStatusFullList | null>): string =>
      params.value ? jobsDashboardStatusMap[params.value] : '',
  },
  {
    field: 'paymentStatus',
    headerName: 'Payment Status',
    width: 150,
    sortable: false,
    valueFormatter: (params: GridValueFormatterParams<JobPaymentStatus | null>): string =>
      params.value ? jobsDashboardPaymentStatusMap[params.value] : '',
  },
  {
    field: 'driverName',
    headerName: 'Driver Name',
    width: 120,
    sortable: false,
    valueGetter: (params: GridValueGetterParams<Job>): string => params.row.driver?.name || '',
  },
  {
    field: 'driverPhone',
    headerName: 'Driver Phone',
    width: 120,
    sortable: false,
    valueGetter: (params: GridValueGetterParams<Job>): string => params.row.driver?.phone || '',
  },
  {
    field: 'driverEmail',
    headerName: 'Driver Email',
    width: 120,
    sortable: false,
    valueGetter: (params: GridValueGetterParams<Job>): string => params.row.driver?.email || '',
  },
  {
    field: 'name',
    headerName: 'Job Name',
    width: 150,
    sortable: false,
  },
  {
    field: 'startTimeRaw',
    headerName: 'Pickup by',
    width: 120,
    sortable: false,
    valueFormatter: gridFormatters.formatLongDate,
  },
  {
    field: 'endTimeRaw',
    headerName: 'Deliver by',
    width: 120,
    sortable: false,
    valueFormatter: gridFormatters.formatLongDate,
  },
  {
    field: 'startLocation',
    headerName: 'Pickup Address',
    width: 150,
    sortable: false,
  },
  {
    field: 'endLocation',
    headerName: 'Drop off Address',
    width: 150,
    sortable: false,
  },
  {
    field: 'paraNotes',
    headerName: 'Para Notes',
    width: 120,
    sortable: false,
    valueGetter: (params: GridValueGetterParams<Job>): string => params.row.notes?.para || '',
  },
  {
    field: 'driverPay',
    headerName: 'Driver Pay',
    width: 120,
    sortable: false,
    valueGetter: (params: GridValueGetterParams<Job>): number => params.row.payment?.final || 0,
    valueFormatter: gridFormatters.formatCurrency,
  },
  {
    field: 'pickupNotes',
    headerName: 'Pickup Notes (for driver)',
    width: 150,
    sortable: false,
    valueGetter: (params: GridValueGetterParams<Job>): string => params.row.notes?.pickup || '',
  },
  {
    field: 'dropoffNotes',
    headerName: 'Drop off Notes (for driver)',
    width: 150,
    sortable: false,
    valueGetter: (params: GridValueGetterParams<Job>): string => params.row.notes?.dropoff || '',
  },
  {
    field: 'sourcePlatform',
    headerName: 'Created from',
    width: 120,
    sortable: false,
  },
  {
    field: 'actions',
    headerName: 'Edit',
    width: 40,
    sortable: false,
    renderCell: (params) => <JobsDashboardItemActionButton selectedJob={params.row} />,
  },
]

export const jobsDashboardPageSizeOptions = [20, 50, 100]

export const JOBS_DASHBOARD_COLUMNS_KEY = 'jobsDashboardColumns'

export const jobsDashboardStatusMap = {
  [JobStatusFullList.Active]: 'Active',
  [JobStatusFullList.Full]: 'Filled',
  [JobStatusFullList.Completed]: 'Completed',
  [JobStatusFullList.Approved]: 'Approved',
  [JobStatusFullList.Published]: 'Published',
  [JobStatusFullList.Unpublished]: 'Unpublished',
  [JobStatusFullList.Deleted]: 'Deleted',
  [JobStatusFullList.Scheduled]: 'Scheduled',
  [JobStatusFullList.Current]: 'Current',
  [JobStatusFullList.Incompleted]: 'Incompleted',
  [JobStatusFullList.CartwheelTimeout]: 'Cartwheel timeout',
  [JobStatusFullList.CartwheelVerification]: 'Cartwheel verification',
  [JobStatusFullList.Canceled]: 'Canceled',
}

export const jobsDashboardPaymentStatusMap = {
  [JobPaymentStatus.Pending]: 'Pending',
  [JobPaymentStatus.Enqueued]: 'Enqueued',
  [JobPaymentStatus.Funding]: 'Funding',
  [JobPaymentStatus.AwaitingContractorAccount]: 'Awaiting contractor account',
  [JobPaymentStatus.Transferring]: 'Transferring',
  [JobPaymentStatus.PendingCancelation]: 'Pending cancelation',
  [JobPaymentStatus.Completed]: 'Completed',
  [JobPaymentStatus.Returned]: 'Returned',
  [JobPaymentStatus.Canceled]: 'Canceled',
  [JobPaymentStatus.ActionRequired]: 'ActionRequired',
  [JobPaymentStatus.Refunded]: 'Refunded',
  [JobPaymentStatus.Submitted]: 'Submitted',
  [JobPaymentStatus.New]: 'New',
}
