import { LoginForm } from 'src/components/auth/login-form/LoginForm'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import LoginImage from 'src/assets/images/login-image.png'
import Logo from 'src/assets/images/logo.png'
import tw from 'twin.macro'

export const LoginPage = () => {
  return (
    <div css={tw`bg-gray-100 flex h-full justify-center items-center`}>
      <div css={tw`max-w-screen-lg h-[748px] my-8 p-6 bg-white flex flex-1 rounded-2xl flex-row`}>
        <img src={LoginImage} css={tw`w-[480px] rounded-lg`} />
        <div css={tw`flex-1 flex-col px-20`}>
          <img src={Logo} css={tw`h-8 mx-auto mt-12 mb-24`} />
          <Typography variant="h4" fontWeight={600}>
            Get started
          </Typography>
          <Typography css={tw`mb-12 mt-4`} color={colors.GRAY_DARK_COOL}>
            Log into your account
          </Typography>
          <LoginForm />
        </div>
      </div>
    </div>
  )
}
