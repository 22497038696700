import { Typography } from '@mui/material'
import { GridColDef, GridColumnHeaderParams, GridValueGetterParams } from '@mui/x-data-grid-pro'
import { ComposeMajor } from '@shopify/polaris-icons'
import { CartwheelJob } from 'src/interfaces/jobs'
import { colors } from 'src/theme'
import { gridFormatters } from 'src/utils/grid'
import tw from 'twin.macro'

export const cartwheelJobsDashboardColumns: Array<GridColDef> = [
  {
    field: 'orderId',
    headerName: 'Order ID',
    width: 120,
  },
  {
    field: 'pickupBy',
    headerName: 'Pickup by',
    width: 150,
    valueFormatter: gridFormatters.formatLongDate,
  },
  {
    field: 'pickupName',
    headerName: 'Pickup Name',
    width: 150,
  },
  {
    field: 'tip',
    headerName: 'Tip',
    width: 120,
    valueGetter: (params: GridValueGetterParams<CartwheelJob, string>): string =>
      params.value || '0',
    valueFormatter: gridFormatters.addCurrencySign,
  },
  {
    field: 'team',
    headerName: 'Team',
    width: 120,
  },
  {
    field: 'dropoffName',
    headerName: 'Drop-off Name',
    width: 120,
  },
  {
    field: 'thirdPartyDspTip',
    headerName: 'Third Party Dsp Tip',
    width: 120,
  },
  {
    field: 'driverPay',
    headerName: 'Total Payout',
    width: 150,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div css={tw`flex items-center`}>
        <Typography variant="body2" fontWeight={500}>
          {params.colDef.headerName}
        </Typography>
        <ComposeMajor css={tw`w-4 h-4 ml-1.5`} color={colors.BRAND_NAVY} />
      </div>
    ),
    valueGetter: (params: GridValueGetterParams<CartwheelJob, string>): string =>
      params.value || '0',
    valueFormatter: gridFormatters.addCurrencySign,
  },
  {
    field: 'source',
    headerName: 'Source',
    width: 120,
  },
  {
    field: 'pickupByUtc',
    headerName: 'Pickup by UTC',
    width: 120,
    valueFormatter: gridFormatters.formatLongDate,
  },
]

export const cartwheelJobsDashboardPageSizeOptions = [20, 50, 100]
