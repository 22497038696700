import React from '@mui/material'
import { useDeleteJobsModal } from 'src/hooks/modals'
import { memo, useCallback, useContext, useState } from 'react'
import { CommonModal } from 'src/components/shared/modal/CommonModal'
import { useDispatch } from 'react-redux'
import { DeleteJobs } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { JobsDashboardContext } from './grid/JobsDashboard.context'
import { showToast } from 'src/utils/toast'

const DeleteJobsModalComponent = () => {
  const dispatch = useDispatch()

  const { isDeleteJobsModalOpen, jobIds, closeDeleteJobsModal } = useDeleteJobsModal()

  const { refreshJobs, setSelectedRows } = useContext(JobsDashboardContext)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleDeleteJobs = useCallback(async () => {
    if (!jobIds.length) {
      closeDeleteJobsModal()

      return
    }

    setIsLoading(true)

    try {
      await createAsyncAction(dispatch, DeleteJobs.request(jobIds))

      setSelectedRows([])
      refreshJobs()

      showToast('Job(s) has been removed', {
        variant: 'success',
      })
    } catch (error: unknown) {
      showToast(error as string, {
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }

    closeDeleteJobsModal()
  }, [jobIds, refreshJobs, setSelectedRows])

  return (
    <CommonModal
      isOpen={isDeleteJobsModalOpen}
      title="Delete job?"
      text="Are you sure you want to delete the selected job(s)?"
      closeLabel="Cancel"
      submitLabel="Delete"
      isLoading={isLoading}
      onSubmit={handleDeleteJobs}
      onClose={closeDeleteJobsModal}
    />
  )
}

export const DeleteJobsModal = memo(DeleteJobsModalComponent)
