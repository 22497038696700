import React from 'react'
import { OutlinedTextFieldProps, TextField, Typography } from '@mui/material'
import { colors } from 'src/theme'
import tw from 'twin.macro'

export interface BasicTextFieldProps<T>
  extends Omit<OutlinedTextFieldProps, 'name' | 'variant' | 'label'> {
  name: keyof T
  label?: string
}

export const BasicTextField = <T extends unknown>({
  name,
  type,
  label,
  css,
  ...rest
}: BasicTextFieldProps<T>) => {
  return (
    <div css={css}>
      <Typography css={tw`mb-1`} variant="body2" fontWeight={600} color={colors.GRAY_COOL_GRAY}>
        {label}
      </Typography>
      <TextField
        name={name as string}
        type={type}
        variant="outlined"
        placeholder={label}
        {...rest}
      />
    </div>
  )
}
