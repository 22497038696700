import Menu from '@mui/material/Menu'
import Button from '@mui/material/Button'
import React, { memo, useCallback, useMemo } from 'react'
import tw from 'twin.macro'
import { Divider, List, SvgIcon, Typography } from '@mui/material'
import { CaretDownMinor, CaretUpMinor, Columns3Minor } from '@shopify/polaris-icons'
import { colors } from 'src/theme'
import { GridColumnsSelectItem } from './GridColumnsSelectItem'
import { GridColDef } from '@mui/x-data-grid-pro'

interface Props {
  columnsConfig: Array<GridColDef>
  includedColumns: Array<string>
  excludedColumns: Array<string>
  pinnedColumns: Array<string>
  hiddenColumns?: Array<string>
  onAddExcludedColumn(column: string): void
  onRemoveExcludedColumn(column: string): void
  onAddPinnedColumn(column: string): void
  onRemovePinnedColumn(column: string): void
}

const GridColumnsSelectComponent = ({
  columnsConfig,
  includedColumns,
  excludedColumns,
  pinnedColumns,
  hiddenColumns,
  onAddExcludedColumn,
  onRemoveExcludedColumn,
  onAddPinnedColumn,
  onRemovePinnedColumn,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const columns = useMemo(() => {
    if (!hiddenColumns) {
      return columnsConfig
    }

    return columnsConfig.filter((columnConfig) => !hiddenColumns.includes(columnConfig.field))
  }, [columnsConfig, hiddenColumns])

  return (
    <>
      <Button
        id="basic-button"
        variant="outlined"
        color="secondary"
        aria-controls={isMenuOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={handleOpen}
        css={[tw`rounded-l-none border border-gray-300`, isMenuOpen && tw`bg-gray-100`]}
        startIcon={
          <SvgIcon>
            <Columns3Minor />
          </SvgIcon>
        }
        endIcon={
          isMenuOpen ? (
            <SvgIcon>
              <CaretUpMinor />
            </SvgIcon>
          ) : (
            <SvgIcon>
              <CaretDownMinor />
            </SvgIcon>
          )
        }
      >
        <Typography fontWeight={500} variant="body2">
          Manage Table
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        css={tw`mt-2 rounded-xl`}
      >
        {includedColumns.length > 0 ? (
          <div>
            <Typography
              fontWeight={600}
              variant="body2"
              color={colors.GRAY_COOL_GRAY}
              css={tw`m-4`}
            >
              ON THE TABLE
            </Typography>
            <Divider />
            <List>
              {columns
                .filter((columnConfig) => includedColumns.includes(columnConfig.field))
                .map((item) => (
                  <GridColumnsSelectItem
                    key={item.field}
                    value={item.field}
                    label={item.headerName}
                    isPinned={pinnedColumns.includes(item.field)}
                    isExcluded={excludedColumns.includes(item.field)}
                    onExclude={onAddExcludedColumn}
                    onInclude={onRemoveExcludedColumn}
                    onPin={onAddPinnedColumn}
                    onUnpin={onRemovePinnedColumn}
                  />
                ))}
            </List>
          </div>
        ) : null}
        {excludedColumns.length > 0 ? (
          <div>
            <Divider />
            <Typography
              fontWeight={600}
              variant="body2"
              color={colors.GRAY_COOL_GRAY}
              css={tw`m-4`}
            >
              NOT ON THE TABLE
            </Typography>
            <Divider />
            <List>
              {columns
                .filter((columnConfig) => excludedColumns.includes(columnConfig.field))
                .map((item) => (
                  <GridColumnsSelectItem
                    key={item.field}
                    value={item.field}
                    label={item.headerName}
                    isPinned={pinnedColumns.includes(item.field)}
                    isExcluded={excludedColumns.includes(item.field)}
                    onExclude={onAddExcludedColumn}
                    onInclude={onRemoveExcludedColumn}
                    onPin={onAddPinnedColumn}
                    onUnpin={onRemovePinnedColumn}
                  />
                ))}
            </List>
          </div>
        ) : null}
      </Menu>
    </>
  )
}

export const GridColumnsSelect = memo(GridColumnsSelectComponent)
