import { ChangeEvent, memo, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { TextField, InputAdornment, SvgIcon } from '@mui/material'
import tw from 'twin.macro'
import { CirclePlusMinor, EditMinor, RefreshMajor, SearchMinor } from '@shopify/polaris-icons'
import { CartwheelAccountButton } from './header/CartwheelAccountButton'
import { CartwheelDashboardContext } from './CartwheelDashboard.context'
import _ from 'lodash'
import { showToast } from 'src/utils/toast'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { useDispatch } from 'react-redux'
import { PublishCartwheelJobs } from 'src/constants/actionTypes'
import { useCartwheelEditPayoutModal } from 'src/hooks/modals'
import { LoadingButton } from '@mui/lab'

const CartwheelGridHeaderComponent = () => {
  const dispatch = useDispatch()

  const { openCartwheelEditPayoutModal } = useCartwheelEditPayoutModal()

  const {
    setSearchQuery,
    refreshJobs,
    isRefreshJobsLoading,
    selectedRows,
    setSelectedRows,
    selectedCartwheelJobs,
  } = useContext(CartwheelDashboardContext)

  const searchDebounce = useRef(_.debounce(setSearchQuery, 700))

  const [isPublishLoading, setIsPublishLoading] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }, [])

  useEffect(() => {
    searchDebounce.current(searchValue)
  }, [searchValue])

  const handlePublishCartwheelJobs = useCallback(async () => {
    setIsPublishLoading(true)

    try {
      await createAsyncAction(
        dispatch,
        PublishCartwheelJobs.request({
          cartWheelDeliveryJobs: selectedCartwheelJobs.map((item) => ({
            orderId: item.orderId,
            orderStatus: item.orderStatus,
            pickupBy: item.pickupBy,
            deliverBy: item.deliverBy,
            pickupName: item.pickupName,
            pickupAddress: item.pickupAddress,
            dropoffAddress: item.dropoffAddress,
            driverPay: item.driverPay,
          })),
        }),
      )

      setSelectedRows([])
      refreshJobs()

      showToast('Jobs has been published to ParaPortal. You can find them on Main Dashboard', {
        variant: 'success',
      })
    } catch (err: unknown) {
      showToast(err as string, {
        variant: 'error',
      })
    }

    setIsPublishLoading(false)
  }, [selectedCartwheelJobs, refreshJobs, setSelectedRows])

  const handleEditTotalPayout = useCallback(() => {
    openCartwheelEditPayoutModal(selectedRows as Array<string>)
  }, [selectedRows])

  return (
    <div css={tw`flex items-end flex-row mt-6`}>
      <CartwheelAccountButton />
      <div css={tw`ml-2`}>
        <LoadingButton
          variant="outlined"
          startIcon={
            <SvgIcon>
              <RefreshMajor />
            </SvgIcon>
          }
          disabled={isRefreshJobsLoading}
          onClick={refreshJobs}
        >
          Refresh Cartwheel sync
        </LoadingButton>
      </div>
      <div css={tw`space-x-2 ml-auto`}>
        <TextField
          variant="outlined"
          placeholder="Search"
          value={searchValue}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon css={tw`w-4 h-4`}>
                  <SearchMinor />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
        />
        <LoadingButton
          variant="outlined"
          startIcon={
            <SvgIcon>
              <EditMinor />
            </SvgIcon>
          }
          disabled={!selectedCartwheelJobs.length}
          onClick={handleEditTotalPayout}
        >
          Edit total payout
        </LoadingButton>
        <LoadingButton
          css={tw`px-8`}
          startIcon={
            <SvgIcon>
              <CirclePlusMinor color="white" />
            </SvgIcon>
          }
          disabled={!selectedCartwheelJobs.length}
          loading={isPublishLoading}
          onClick={handlePublishCartwheelJobs}
          variant="contained"
        >
          Publish jobs to Para
        </LoadingButton>
      </div>
    </div>
  )
}

export const CartwheelGridHeader = memo(CartwheelGridHeaderComponent)
