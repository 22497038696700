import { fork } from 'redux-saga/effects'
import { cancelationPolicySaga } from './cancelationPolicy'
import { cartwheelSaga } from './cartwheel'
import { jobsCsvSaga } from './csv'
import { jobsSaga } from './jobs'

export function* jobsRootSaga() {
  yield fork(cancelationPolicySaga)
  yield fork(jobsSaga)
  yield fork(jobsCsvSaga)
  yield fork(cartwheelSaga)
}
