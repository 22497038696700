import { Info } from '@mui/icons-material'
import tw from 'twin.macro'

export interface InfoContainerProps {
  children?: React.ReactNode
  className?: string
}

export const InfoContainer = ({ children, className }: InfoContainerProps) => {
  return (
    <div css={tw`bg-blue-100 p-4 rounded flex flex-row`} className={className}>
      <Info css={tw`mr-4`} color="primary" />
      <div css={tw`flex flex-col`}>{children}</div>
    </div>
  )
}
