import { ChangeEvent, memo, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { TextField, InputAdornment, SvgIcon, Button } from '@mui/material'
import tw from 'twin.macro'
import { SearchMinor, SettingsMinor } from '@shopify/polaris-icons'
import { JobStatusSelect } from './header/JobStatusSelect'
import { JobsDashboardFilterDateAfter } from './header/JobsDashboardFilterDateAfter'
import { JobsDashboardBatchActionButton } from './header/JobsDashboardBatchActionButton'
import { useCancelationPolicyModal } from 'src/hooks/modals'
import { JobsDashboardGridColumnsSelect } from './header/JobsDashboardGridColumnsSelect'
import { JobsDashboardAddJobButton } from './header/JobsDashboardAddJobButton'
import { JobsDashboardContext } from './JobsDashboard.context'
import _ from 'lodash'

const JobsGridHeaderComponent = () => {
  const { openCancelationPolicyModal } = useCancelationPolicyModal()

  const { setSearchQuery } = useContext(JobsDashboardContext)

  const searchDebounce = useRef(_.debounce(setSearchQuery, 700))

  const [searchValue, setSearchValue] = useState<string>('')

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }, [])

  useEffect(() => {
    searchDebounce.current(searchValue)
  }, [searchValue])

  return (
    <div css={tw`w-full flex items-center flex-row mt-6`}>
      <TextField
        variant="outlined"
        placeholder="Search"
        value={searchValue}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon css={tw`w-4 h-4`}>
                <SearchMinor />
              </SvgIcon>
            </InputAdornment>
          ),
        }}
      />
      <div css={tw`ml-2 flex flex-1 flex-col items-end xl:flex-row`}>
        <div>
          <JobStatusSelect />
          <JobsDashboardFilterDateAfter />
          <JobsDashboardGridColumnsSelect />
        </div>
        <div css={tw`space-x-2 mt-2 xl:ml-auto xl:mt-0`}>
          <JobsDashboardBatchActionButton />
          <Button
            variant="outlined"
            startIcon={
              <SvgIcon>
                <SettingsMinor />
              </SvgIcon>
            }
            onClick={openCancelationPolicyModal}
          >
            Cancellation Policy
          </Button>
          <JobsDashboardAddJobButton css={tw`px-8`} />
        </div>
      </div>
    </div>
  )
}

export const JobsGridHeader = memo(JobsGridHeaderComponent)
