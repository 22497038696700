import React, { ChangeEvent, memo, useCallback, useContext, useMemo, useState } from 'react'
import { InputAdornment, Modal, Typography } from '@mui/material'
import tw from 'twin.macro'
import { useCartwheelEditPayoutModal } from 'src/hooks/modals'
import { colors } from 'src/theme'
import { CartwheelDashboardContext } from './grid/CartwheelDashboard.context'
import { CartwheelJob } from 'src/interfaces/jobs'
import { BasicTextField } from 'src/components/shared/form/BasicTextField'
import { showToast } from 'src/utils/toast'
import { LoadingButton } from '@mui/lab'

const CartwheelEditPayoutModalComponent = () => {
  const { isCartwheelEditPayoutModalOpen, jobIds, closeCartwheelEditPayoutModal } =
    useCartwheelEditPayoutModal()

  const { partialEditCartwheelJobs } = useContext(CartwheelDashboardContext)

  const [inputValue, setInputValue] = useState<string>('')

  const container = useMemo(() => document.getElementById('root'), [])

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }, [])

  const handleCloseModal = useCallback(() => {
    setInputValue('')
    closeCartwheelEditPayoutModal()
  }, [])

  const handleSubmit = useCallback(() => {
    const updatedJobList = jobIds.map<Partial<CartwheelJob>>((jobId) => ({
      orderId: jobId,
      driverPay: parseFloat(inputValue).toFixed(2),
    }))

    partialEditCartwheelJobs(updatedJobList)

    showToast('Total payout has been changed', {
      variant: 'success',
    })

    handleCloseModal()
  }, [inputValue, jobIds, partialEditCartwheelJobs])

  return (
    <Modal
      container={container}
      open={isCartwheelEditPayoutModalOpen}
      onClose={handleCloseModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`flex justify-center items-center`}
    >
      <div css={tw`w-[440px] bg-white px-10 py-12 rounded-2xl`}>
        <Typography css={tw`mb-8`} variant="h5">
          Edit total payout
        </Typography>
        <BasicTextField
          css={tw`w-full`}
          name="totalPayout"
          type="number"
          label="Total payout"
          value={inputValue}
          onChange={handleInputChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography color={colors.GRAY_COOL_GRAY}>$</Typography>
              </InputAdornment>
            ),
          }}
        />
        <div css={tw`flex items-center justify-between mt-8`}>
          <LoadingButton css={tw`w-[130px]`} color="secondary" onClick={handleCloseModal}>
            Cancel
          </LoadingButton>
          <LoadingButton css={tw`w-[130px]`} onClick={handleSubmit}>
            Save
          </LoadingButton>
        </div>
      </div>
    </Modal>
  )
}

export const CartwheelEditPayoutModal = memo(CartwheelEditPayoutModalComponent)
