import React, { memo, useContext } from 'react'
import { GridColumnsSelect } from 'src/components/shared/grid/grid-columns-select/GridColumnsSelect'
import { jobsDashboardColumns } from 'src/constants/jobs/jobDashboardGrid'
import { JobsDashboardContext } from '../JobsDashboard.context'

const hiddenColumns = ['actions']

const JobsDashboardGridColumnsSelectComponent = () => {
  const {
    includedColumns,
    excludedColumns,
    pinnedColumns,
    handleAddExcludedColumn,
    handleRemoveExcludedColumn,
    handleAddPinnedColumn,
    handleRemovePinnedColumn,
  } = useContext(JobsDashboardContext)

  return (
    <GridColumnsSelect
      columnsConfig={jobsDashboardColumns}
      includedColumns={includedColumns}
      excludedColumns={excludedColumns}
      pinnedColumns={pinnedColumns}
      hiddenColumns={hiddenColumns}
      onAddExcludedColumn={handleAddExcludedColumn}
      onRemoveExcludedColumn={handleRemoveExcludedColumn}
      onAddPinnedColumn={handleAddPinnedColumn}
      onRemovePinnedColumn={handleRemovePinnedColumn}
    />
  )
}

export const JobsDashboardGridColumnsSelect = memo(JobsDashboardGridColumnsSelectComponent)
