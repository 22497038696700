import * as Yup from 'yup'

export const AddJobSchema = Yup.object().shape({
  name: Yup.string().required('Job Name is required'),
  price: Yup.string().required('Price is required'),
  startDate: Yup.string().required('Start Date is required'),
  startTime: Yup.string().required('Start Time is required'),
  endDate: Yup.string().required('End Date is required'),
  endTime: Yup.string().required('End Time is required'),
  startLocation: Yup.string().required('Start Location is required'),
  endLocation: Yup.string().required('End Location is required'),
})
