import Menu from '@mui/material/Menu'
import React, { memo } from 'react'
import tw from 'twin.macro'
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  PopoverOrigin,
  SvgIcon,
  Typography,
} from '@mui/material'
import { colors } from 'src/theme'
import { MenuActionItem } from 'src/interfaces/ui'

interface Props {
  items: MenuActionItem[]
  isOpen?: boolean
  anchorEl?: any
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  onClose(): void
}

const ActionMenuComponent = ({
  items,
  isOpen,
  anchorEl,
  anchorOrigin,
  transformOrigin,
  onClose,
}: Props) => {
  return (
    <Menu
      id="menu-list"
      css={tw`mt-2 rounded-xl`}
      open={Boolean(isOpen)}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}
    >
      <List>
        {items.map(({ label, Icon, color, onClick }) => (
          <ListItem key={label} css={tw`h-10`} disablePadding>
            <ListItemButton onClick={onClick} css={tw`pr-6`} dense>
              <ListItemIcon css={tw`min-w-0 mr-4`}>
                <SvgIcon css={tw`w-5 h-5`}>
                  <Icon color={color || colors.GRAY_DARK_COOL} />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary={<Typography color={color || colors.GRAY_DARK_COOL}>{label}</Typography>}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Menu>
  )
}

export const ActionMenu = memo(ActionMenuComponent)
