import './index.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import { GlobalStyles } from 'twin.macro'
import { LicenseInfo } from '@mui/x-license-pro'

if (process.env.REACT_APP_MUI_LICENSE_KEY) {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY)
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <>
    <GlobalStyles />
    <App />
  </>,
)
