import { createActions } from 'src/utils/reduxUtils'

export const LoginEmailPassword = createActions('LoginEmailPassword')

export const FetchUser = createActions('FetchUser')

export const SetUser = createActions('SetUser')

export const SetUserToken = createActions('SetUserToken')

export const Logout = createActions('Logout')

export const CreateJob = createActions('CreateJob')

export const EditJob = createActions('EditJob')

export const DeleteJobs = createActions('DeleteJob')

export const UnassignDrivers = createActions('UnassignDrivers')

export const UploadJobsCsv = createActions('UploadJobsCsv')

export const ConnectCartwheel = createActions('ConnectCartwheel')

export const DeleteCartwheelAccount = createActions('DeleteCartwheelAccount')

export const SetConnectCartwheelModal = createActions('SetConnectCartwheelModal')

export const SetDeleteCartwheelAccountModal = createActions('SetDeleteCartwheelAccountModal')

export const SetJobModal = createActions('SetJobModal')

export const SetDeleteJobsModal = createActions('SetDeleteJobsModal')

export const SetUnassignDriversModal = createActions('SetUnassignDriversModal')

export const SetUploadCSVModal = createActions('SetUploadCSVModal')

export const SetCancelationPolicyModal = createActions('SetCancelationPolicyModal')

export const UpdateCancelationPolicy = createActions('UpdateCancelationPolicy')

export const SetCancelationPolicy = createActions('SetCancelationPolicy')

export const FetchJobsDashboard = createActions('FetchJobsDashboard')

export const FetchCartwheelJobsDashboard = createActions('FetchCartwheelJobsDashboard')

export const PublishCartwheelJobs = createActions('PublishCartwheelJobs')

export const SetCartwheelEditPayoutModal = createActions('SetCartwheelEditPayoutModal')
