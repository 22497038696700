import React, { memo, useContext } from 'react'
import { DateCalendarSelect } from 'src/components/shared/form/DateCalendarSelect'
import { JobsDashboardContext } from '../JobsDashboard.context'
import { DEFAULT_DATE_FORMAT } from 'src/constants/date'

const JobsDashboardFilterDateAfterComponent = () => {
  const { filterDateAfter, handleChangeFilterDateAfter } = useContext(JobsDashboardContext)

  return (
    <DateCalendarSelect
      value={filterDateAfter}
      format={DEFAULT_DATE_FORMAT}
      onChange={handleChangeFilterDateAfter}
    />
  )
}

export const JobsDashboardFilterDateAfter = memo(JobsDashboardFilterDateAfterComponent)
