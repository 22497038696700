import { Typography } from '@mui/material'
import tw from 'twin.macro'

interface Props {
  error: string | null
  css?: any
}

export const FormErrorMessage = ({ error, ...rest }: Props) => {
  if (!error) {
    return null
  }

  return (
    <div css={[tw`mb-4 text-center`]} {...rest}>
      <Typography color="error.main">{error}</Typography>
    </div>
  )
}
