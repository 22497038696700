import React, { memo, useCallback, useMemo } from 'react'
import { Modal, Typography } from '@mui/material'
import { ConnectCartwheelForm } from './connect-cartwheel-form/ConnectCartwheelForm'
import tw from 'twin.macro'
import { useConnectCartwheelModal } from 'src/hooks/modals'
import { colors } from 'src/theme'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'

const ConnectCartwheelModalComponent = () => {
  const navigate = useNavigate()

  const { isConnectCartwheelModalOpen, connectCartwheelModalEditMode, closeConnectCartwheelModal } =
    useConnectCartwheelModal()

  const container = useMemo(() => document.getElementById('root'), [])

  const handleSubmit = useCallback(() => {
    closeConnectCartwheelModal()
    navigate(ROUTES.IMPORT_CARTWHEEL_URL)
  }, [])

  return (
    <Modal
      container={container}
      open={isConnectCartwheelModalOpen}
      onClose={closeConnectCartwheelModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`flex justify-center items-center`}
    >
      <div css={tw`w-[440px] bg-white px-10 py-12 rounded-2xl`}>
        <Typography variant="subtitle1">Cartwheel</Typography>
        <Typography css={tw`mt-2 mb-8`} color={colors.GRAY_DARK_COOL}>
          {connectCartwheelModalEditMode
            ? 'Edit your Cartwheel account details'
            : 'Connect your Cartwheel account'}
        </Typography>
        <ConnectCartwheelForm onSubmit={handleSubmit} />
      </div>
    </Modal>
  )
}

export const ConnectCartwheelModal = memo(ConnectCartwheelModalComponent)
