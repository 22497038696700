export enum JobStatus {
  Active = 'active',
  Full = 'full',
  Completed = 'completed',
  Canceled = 'cancelled',
}

export enum JobStatusFullList {
  Active = 'active',
  Full = 'full',
  Completed = 'completed',
  Approved = 'approved',
  Published = 'published',
  Unpublished = 'unpublished',
  Deleted = 'deleted',
  Scheduled = 'scheduled',
  Current = 'current',
  Incompleted = 'incompleted',
  CartwheelTimeout = 'cartwheel_timeout',
  CartwheelVerification = 'cartwheel_verification',
  Canceled = 'cancelled',
}

export enum JobPaymentStatus {
  Pending = 'pending',
  Enqueued = 'enqueued',
  Funding = 'funding',
  AwaitingContractorAccount = 'awaiting_contractor_account',
  Transferring = 'transferring',
  PendingCancelation = 'pending_cancelation',
  Completed = 'completed',
  Returned = 'returned',
  Canceled = 'canceled',
  ActionRequired = 'action_required',
  Refunded = 'refunded',
  Submitted = 'submitted',
  New = 'new',
}
