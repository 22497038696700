import { DataGridPro, DataGridProProps, GridValidRowModel } from '@mui/x-data-grid-pro'
import React, { memo } from 'react'

interface Props<R extends GridValidRowModel = any> extends DataGridProProps<R> {}

const GridTableComponent = ({
  rows,
  columns,
  pinnedColumns,
  checkboxSelection,
  rowSelectionModel,
  onRowSelectionModelChange,
  ...rest
}: Props) => {
  return (
    <DataGridPro
      rows={rows}
      columns={columns}
      pinnedColumns={pinnedColumns}
      checkboxSelection={checkboxSelection}
      disableRowSelectionOnClick={checkboxSelection}
      rowSelectionModel={rowSelectionModel}
      disableColumnFilter
      disableColumnMenu
      disableColumnReorder
      disableColumnResize
      disableColumnSelector
      onRowSelectionModelChange={onRowSelectionModelChange}
      {...rest}
    />
  )
}

export const GridTable = memo(GridTableComponent)
