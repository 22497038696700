import { combineReducers } from 'redux'
import { authReducer } from './auth'
import { uiReducer } from './ui'
import { modalsReducer } from './modals'

export const rootReducer = combineReducers({
  auth: authReducer,
  ui: uiReducer,
  modals: modalsReducer,
})
