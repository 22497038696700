import React, { memo, useCallback, useContext, MouseEvent } from 'react'
import tw from 'twin.macro'
import { Button, SvgIcon } from '@mui/material'
import { MobileVerticalDotsMajor } from '@shopify/polaris-icons'
import { colors } from 'src/theme'
import { JobsDashboardContext } from '../JobsDashboard.context'
import { Job } from 'src/interfaces/jobs'

interface Props {
  selectedJob: Job
}

const JobsDashboardItemActionButtonComponent = ({ selectedJob }: Props) => {
  const { openJobsDashboardItemAction } = useContext(JobsDashboardContext)

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      openJobsDashboardItemAction(event, selectedJob)
    },
    [selectedJob],
  )

  return (
    <Button id={selectedJob.id} css={tw`min-w-0 min-h-0 h-8 w-8 `} onClick={handleClick}>
      <SvgIcon>
        <MobileVerticalDotsMajor color={colors.GRAY_DARK_COOL} height={20} />
      </SvgIcon>
    </Button>
  )
}

export const JobsDashboardItemActionButton = memo(JobsDashboardItemActionButtonComponent)
