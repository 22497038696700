import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import { FetchUser, UpdateCancelationPolicy } from 'src/constants/actionTypes'
import { ApiService, callSecureApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'

interface UpdateCancelationPolicyRequest {
  cancelationPolicy?: string
  driverCancelationPolicy?: string
}

function* onUpdateCancelationPolicy(action: AsyncAction<UpdateCancelationPolicyRequest>) {
  try {
    yield call(callSecureApi, Endpoints.UpdateCancelationPolicy, {
      apiService: ApiService.Paraworks,
      method: 'PUT',
      body: action.payload,
    })

    yield put(UpdateCancelationPolicy.success())
    yield put(FetchUser.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage =
      typeof err?.payload?.message === 'string'
        ? err?.payload?.message
        : 'Failed update cancelation policies'

    yield put(UpdateCancelationPolicy.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* cancelationPolicySaga() {
  yield takeEvery(UpdateCancelationPolicy.type.REQUEST, onUpdateCancelationPolicy)
}
