import React, { memo, useMemo, useCallback } from 'react'
import { Modal, Typography } from '@mui/material'
import tw from 'twin.macro'
import { colors } from 'src/theme'
import { LoadingButton } from '@mui/lab'

interface Props {
  isOpen?: boolean
  closeOnBackdrop?: boolean
  title?: string
  text?: string
  isLoading?: boolean
  submitLabel?: string
  closeLabel?: string
  onClose(): void
  onSubmit?(): void
}

const CommonModalComponent = ({
  isOpen,
  closeOnBackdrop,
  title,
  text,
  submitLabel,
  closeLabel,
  isLoading,
  onClose,
  onSubmit,
}: Props) => {
  const container = useMemo(() => document.getElementById('root'), [])

  const handleClose = useCallback(
    (_e: any, reason: string) => {
      if (!closeOnBackdrop && reason && reason == 'backdropClick') {
        return
      }

      onClose()
    },
    [closeOnBackdrop, onClose],
  )

  return (
    <Modal
      container={container}
      open={Boolean(isOpen)}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`flex justify-center items-center`}
    >
      <div css={tw`w-[440px] bg-white p-10 rounded-2xl`}>
        {title && (
          <Typography css={tw`mb-4`} variant="h5">
            {title}
          </Typography>
        )}
        {text && (
          <Typography css={tw`mb-10`} color={colors.GRAY_DARK_COOL}>
            {text}
          </Typography>
        )}
        {submitLabel && (
          <LoadingButton
            fullWidth
            loading={isLoading}
            onClick={onSubmit || onClose}
            variant="contained"
          >
            {submitLabel}
          </LoadingButton>
        )}
        <LoadingButton css={tw`mt-2`} fullWidth variant="text" color="secondary" onClick={onClose}>
          {closeLabel || 'Close'}
        </LoadingButton>
      </div>
    </Modal>
  )
}

export const CommonModal = memo(CommonModalComponent)
