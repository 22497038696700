import React from '@mui/material'
import { JobForm } from './job-form/JobForm'
import { useJobModal } from 'src/hooks/modals'
import { DrawerModal } from 'src/components/shared/modal/DrawerModal'

export const JobModal = () => {
  const { isJobModalOpen, job, closeJobModal } = useJobModal()

  return (
    <DrawerModal
      isOpen={isJobModalOpen}
      title={job ? 'Edit Job' : 'Add Job'}
      onClose={closeJobModal}
    >
      <JobForm job={job} onSubmit={closeJobModal} />
    </DrawerModal>
  )
}
