import { GridPaginationModel } from '@mui/x-data-grid-pro'
import { useMemo, useState } from 'react'

export const useGridPagination = (pageSizeOptions: Array<number>) => {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: pageSizeOptions[0],
    page: 0,
  })

  const offset = useMemo(() => {
    return paginationModel.pageSize * paginationModel.page
  }, [paginationModel])

  return {
    paginationModel,
    limit: paginationModel.pageSize,
    offset,
    setPaginationModel,
  }
}
