export enum Endpoints {
  LoginEmailPassword = '/v1/portal/sign_in_partner',

  FetchUser = '/v1/portal/partner',
  UpdateCancelationPolicy = '/v1/portal/partner/edit_policies',

  FetchJobsDashboard = '/v1/portal/delivery',
  CreateJob = '/v1/portal/delivery',
  UpdateJob = '/v1/portal/delivery',
  DeleteJobs = '/v1/portal/delivery',
  UnassignDrivers = '/v1/portal/delivery/unassign_driver',

  UploadJobsCsv = '/v1/portal/delivery/upload_csv',

  ConnectCartwheel = '/v1/portal/cartwheel/link_account',
  DeleteCartwheelAccount = '/v1/portal/cartwheel/delete_account',
  FetchCartwheelJobsDashboard = '/v1/portal/cartwheel/jobs',
  PublishCartwheelJobs = '/v1/portal/cartwheel/import',
}
