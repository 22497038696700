import { useFormikContext } from 'formik'
import { BasicDatePicker } from '../BasicDatePicker'
import { Dayjs } from 'dayjs'
import { useCallback } from 'react'

interface Props<T> {
  name: keyof T
  label: string
}

export const FormikDatePicker = <T extends unknown>({ name, label }: Props<T>) => {
  const { values, touched, errors, setFieldValue } = useFormikContext<T>()

  const isTouched = touched[name] as boolean
  const error = errors[name] as string | undefined

  const onChange = useCallback(
    (date: Dayjs | null) => {
      setFieldValue(name as string, date)
    },
    [name, setFieldValue],
  )

  return (
    <BasicDatePicker
      name={name as string}
      onChange={onChange}
      value={values[name] as unknown as Dayjs | undefined}
      error={Boolean(isTouched && error)}
      helperText={isTouched && error}
      label={label}
    />
  )
}
