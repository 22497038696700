export const transformToFixedWithoutEndZero = (value: number, fractionDigits: number) => {
  const decimalMultiplier = Math.pow(10, fractionDigits)
  return (parseFloat(value.toFixed(fractionDigits)) * decimalMultiplier) / decimalMultiplier
}

export const transformStringToOptions = (list: string[]): { label: string; value: string }[] =>
  list.map((item) => ({ label: item, value: item }))

export const formatCurrencyFromCents = (value: number) => {
  return `$${(value / 100).toFixed(2)}`
}
