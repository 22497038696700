import React, { memo, useCallback, useMemo, useState } from 'react'
import { Modal, Typography } from '@mui/material'
import { CSVInput } from './CSVInput'
import tw from 'twin.macro'
import { useUploadCSVModal } from 'src/hooks/modals'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { useDispatch } from 'react-redux'
import { UploadJobsCsv } from 'src/constants/actionTypes'
import { showToast } from 'src/utils/toast'
import { LoadingButton } from '@mui/lab'

const CSV_ACCEPT_TYPES = ['text/csv']

const UploadCSVModalComponent = () => {
  const dispatch = useDispatch()

  const { isUploadCSVModalOpen, closeUploadCSVModal } = useUploadCSVModal()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const container = useMemo(() => document.getElementById('root'), [])

  const handleUploadCsv = useCallback(async (file: File) => {
    setIsLoading(true)

    try {
      await createAsyncAction(
        dispatch,
        UploadJobsCsv.request({
          file,
        }),
      )

      showToast('Jobs has been uploaded', {
        variant: 'success',
      })
    } catch (err: unknown) {
      showToast(err as string, {
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
      closeUploadCSVModal()
    }
  }, [])

  return (
    <Modal
      container={container}
      open={isUploadCSVModalOpen}
      onClose={closeUploadCSVModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`flex justify-center items-center`}
    >
      <div css={tw`w-[440px] bg-white p-8 rounded-2xl`}>
        <Typography css={tw`mb-4`} variant="subtitle1">
          Upload CSV File
        </Typography>
        <CSVInput acceptTypes={CSV_ACCEPT_TYPES} isLoading={isLoading} onSelect={handleUploadCsv} />
        <LoadingButton onClick={closeUploadCSVModal} css={tw`w-[120px]`} color="secondary">
          Cancel
        </LoadingButton>
      </div>
    </Modal>
  )
}

export const UploadCSVModal = memo(UploadCSVModalComponent)
