import { memo, useEffect } from 'react'
import { useAsyncDispatchWithErrorToast } from './hooks/useAsyncDispatchWithErrorToast'

export const SecureHooksContainer = memo(() => {
  const { asyncDispatchWithErrorToast } = useAsyncDispatchWithErrorToast()

  useEffect(() => {
    //
  }, [])

  return <div />
})
