import { Routes, Route, Navigate } from 'react-router-dom'
import { SecureHooksContainer } from 'src/SecureHooksContainer'
import React, { memo } from 'react'
import { ROUTES } from 'src/constants/routes'
import { JobsPage } from 'src/pages/jobs/JobsPage'
import { ImportCartwheelPage } from 'src/pages/jobs/ImportCartwheelPage'
import { JobsDashboardPage } from 'src/pages/jobs/JobsDashboardPage'

export const SecureRoutes = memo(() => {
  return (
    <>
      <Routes>
        <Route path={ROUTES.JOBS_URL} element={<JobsPage />}>
          <Route index element={<Navigate to={ROUTES.JOBS_DASHBOARD} />} />
          <Route path={ROUTES.JOBS_DASHBOARD} element={<JobsDashboardPage />} />
          <Route path={ROUTES.IMPORT_CARTWHEEL_URL} element={<ImportCartwheelPage />} />
          <Route path="*" element={<Navigate to={ROUTES.JOBS_DASHBOARD} />} />
        </Route>
        <Route path="*" element={<Navigate to={ROUTES.JOBS_URL} />} />
      </Routes>
      <SecureHooksContainer />
    </>
  )
})
