import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { JobsGridHeader } from './JobsGridHeader'
import { JobsGrid } from './JobsGrid'
import { useDispatch } from 'react-redux'
import { Job } from 'src/interfaces/jobs'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { FetchJobsDashboard } from 'src/constants/actionTypes'
import { useGridColumns } from 'src/utils/hooks/grid/useGridColumns'
import {
  jobsDashboardColumns,
  jobsDashboardPageSizeOptions,
  JOBS_DASHBOARD_COLUMNS_KEY,
} from 'src/constants/jobs/jobDashboardGrid'
import { IJobsDashboardContext, JobsDashboardContext } from './JobsDashboard.context'
import dayjs from 'dayjs'
import { DEFAULT_DATE_FORMAT } from 'src/constants/date'
import { GridPaginationModel, GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { useGridPagination } from 'src/utils/hooks/grid/useGridPagination'
import { JobsDashboardItemActionMenu } from './action/JobsDashboardItemActionMenu'
import { JobStatus } from 'src/constants/jobs/enums'
import { JobModal } from 'src/components/jobs/dashboard/JobModal'
import { CancelationPolicyModal } from '../cancelation-policy/CancelationPolicyModal'
import { DeleteJobsModal } from '../DeleteJobsModal'
import { UnassignDriversModal } from '../UnassignDriversModal'
import { showToast } from 'src/utils/toast'

interface FetchJobsOptions {
  filterDateAfter: string
  limit: number
  offset: number
  statusFilter: Array<JobStatus>
  searchQuery: string
}

const JobsDashboardComponent = () => {
  const dispatch = useDispatch()

  const {
    includedColumns,
    excludedColumns,
    pinnedColumns,
    handleAddExcludedColumn,
    handleRemoveExcludedColumn,
    handleAddPinnedColumn,
    handleRemovePinnedColumn,
  } = useGridColumns(jobsDashboardColumns, JOBS_DASHBOARD_COLUMNS_KEY)

  const { paginationModel, limit, offset, setPaginationModel } = useGridPagination(
    jobsDashboardPageSizeOptions,
  )

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [jobs, setJobs] = useState<Array<Job>>([])
  const [totalJobs, setTotalJobs] = useState<number>(0)
  const [filterDateAfter, setFilterDateAfter] = useState<string>(
    dayjs().add(-7, 'days').format(DEFAULT_DATE_FORMAT),
  )
  const [actionAnchor, setActionAnchor] = useState<null | HTMLElement>(null)
  const [selectedJob, setSelectedJob] = useState<Job | null>(null)
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([])
  const [statusFilter, setStatusFilter] = useState<Array<JobStatus>>([])
  const [searchQuery, setSearchQuery] = useState<string>('')

  const fetchJobs = useCallback(
    async ({ filterDateAfter, limit, offset, statusFilter, searchQuery }: FetchJobsOptions) => {
      setIsLoading(true)

      try {
        const payload: { data: Array<Job>; total: number } = await createAsyncAction(
          dispatch,
          FetchJobsDashboard.request({
            limit,
            offset,
            filterDateAfter,
            statusFilter,
            searchQuery,
          }),
        )

        setJobs(payload.data)
        setTotalJobs(payload.total)
      } catch (err: unknown) {
        showToast(err as string, {
          variant: 'error',
        })
      }

      setIsLoading(false)
    },
    [],
  )

  const fetchJobsWithFilters = useCallback(async () => {
    await fetchJobs({ filterDateAfter, limit, offset, statusFilter, searchQuery })
  }, [filterDateAfter, limit, offset, statusFilter, searchQuery])

  useEffect(() => {
    fetchJobs({
      filterDateAfter,
      limit,
      offset,
      statusFilter,
      searchQuery,
    })
  }, [filterDateAfter, limit, offset, statusFilter, searchQuery])

  const handleChangePagination = useCallback((paginationModel: GridPaginationModel) => {
    setPaginationModel(paginationModel)
  }, [])

  const openJobsDashboardItemAction = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, jobToEdit: Job) => {
      setActionAnchor(event.currentTarget)
      setSelectedJob(jobToEdit)
    },
    [],
  )

  const closeJobsDashboardItemAction = useCallback(() => {
    setActionAnchor(null)
    setSelectedJob(null)
  }, [])

  const selectedJobs = useMemo<Array<Job>>(() => {
    return selectedRows.reduce<Array<Job>>((arr, selectedRowId) => {
      const job = jobs.find((item) => item.id === selectedRowId)

      if (job) {
        arr.push(job)
      }

      return arr
    }, [])
  }, [jobs, selectedRows])

  const jobsDashboardContextValue = useMemo<IJobsDashboardContext>(() => {
    return {
      includedColumns,
      excludedColumns,
      pinnedColumns,
      handleAddExcludedColumn,
      handleRemoveExcludedColumn,
      handleAddPinnedColumn,
      handleRemovePinnedColumn,

      filterDateAfter,
      handleChangeFilterDateAfter: setFilterDateAfter,

      selectedRows,
      setSelectedRows,

      selectedJobs,

      statusFilter,
      setStatusFilter,

      searchQuery,
      setSearchQuery,

      refreshJobs: fetchJobsWithFilters,

      actionAnchor,
      selectedJob,
      openJobsDashboardItemAction,
      closeJobsDashboardItemAction,
    }
  }, [
    includedColumns,
    excludedColumns,
    pinnedColumns,
    handleAddExcludedColumn,
    handleRemoveExcludedColumn,
    handleAddPinnedColumn,
    handleRemovePinnedColumn,

    filterDateAfter,

    selectedRows,

    selectedJobs,

    statusFilter,

    searchQuery,

    fetchJobsWithFilters,

    actionAnchor,
    selectedJob,
  ])

  return (
    <JobsDashboardContext.Provider value={jobsDashboardContextValue}>
      <div>
        <JobsGridHeader />
        <JobsGrid
          jobs={jobs}
          pinnedColumns={pinnedColumns}
          excludedColumns={excludedColumns}
          isLoading={isLoading}
          paginationModel={paginationModel}
          pageSizeOptions={jobsDashboardPageSizeOptions}
          rowCount={totalJobs}
          rowSelectionModel={selectedRows}
          onPaginationModelChange={handleChangePagination}
          onRowSelectionModelChange={setSelectedRows}
        />
        <JobsDashboardItemActionMenu />
        <CancelationPolicyModal />
        <JobModal />
        <DeleteJobsModal />
        <UnassignDriversModal />
      </div>
    </JobsDashboardContext.Provider>
  )
}

export const JobsDashboard = memo(JobsDashboardComponent)
