import React, { memo, useCallback, useContext, useMemo } from 'react'
import { CustomerMinusMajor, EditMinor, DeleteMinor } from '@shopify/polaris-icons'
import { colors } from 'src/theme'
import { JobsDashboardContext } from '../JobsDashboard.context'
import { MenuActionItem } from 'src/interfaces/ui'
import { ActionMenu } from 'src/components/shared/ActionMenu'
import { useDeleteJobsModal, useJobModal, useUnassignDriversModal } from 'src/hooks/modals'

const JobsDashboardItemActionMenuComponent = () => {
  const { openJobModal } = useJobModal()
  const { openDeleteJobsModal } = useDeleteJobsModal()
  const { openUnassignDriversModal } = useUnassignDriversModal()

  const { actionAnchor, selectedJob, closeJobsDashboardItemAction } =
    useContext(JobsDashboardContext)

  const isOpen = Boolean(actionAnchor)

  const handleEditOrder = useCallback(() => {
    if (!selectedJob) {
      return
    }

    openJobModal(selectedJob)

    closeJobsDashboardItemAction()
  }, [selectedJob, openJobModal, closeJobsDashboardItemAction])

  const handleUnassignDriver = useCallback(() => {
    if (!selectedJob?.driverJobId) {
      return
    }

    openUnassignDriversModal([selectedJob.driverJobId])

    closeJobsDashboardItemAction()
  }, [selectedJob, openUnassignDriversModal, closeJobsDashboardItemAction])

  const handleDeleteJob = useCallback(() => {
    if (!selectedJob?.id) {
      return
    }

    openDeleteJobsModal([selectedJob.id])

    closeJobsDashboardItemAction()
  }, [selectedJob, openDeleteJobsModal, closeJobsDashboardItemAction])

  const actionItems: MenuActionItem[] = useMemo(() => {
    const res = [
      {
        label: 'Edit Order',
        Icon: EditMinor,
        onClick: handleEditOrder,
      },
      {
        label: 'Delete Job',
        Icon: DeleteMinor,
        color: colors.ERROR,
        onClick: handleDeleteJob,
      },
    ]

    if (selectedJob?.driverJobId) {
      res.unshift({
        label: 'Unassign driver',
        Icon: CustomerMinusMajor,
        onClick: handleUnassignDriver,
      })
    }

    return res
  }, [handleEditOrder, handleDeleteJob, handleUnassignDriver])

  return (
    <ActionMenu
      anchorEl={actionAnchor}
      isOpen={isOpen}
      items={actionItems}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={closeJobsDashboardItemAction}
    />
  )
}

export const JobsDashboardItemActionMenu = memo(JobsDashboardItemActionMenuComponent)
